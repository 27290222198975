html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
h1 {
  font-weight: 700;
  font-size: 3rem;
}
h2 {
  font-weight: 700;
  font-size: 2.25rem;
}
h3 {
  font-weight: 700;
  font-size: 1.5rem;
}
h4 {
  font-weight: 700;
  font-size: 1.125rem;
}
h5 {
  font-weight: 700;
  font-size: 0.875rem;
}
h6 {
  font-weight: 700;
  font-size: 0.75rem;
}
p {
  line-height: 1.4;
}
a {
  display: inline-block;
  color: #4183d7;
  text-decoration: none;
}
a .icon {
  fill: #4183d7;
}
a:hover {
  color: #446cb3;
}
a:hover .icon {
  fill: #446cb3;
}
strong,
.bold {
  font-weight: 700;
}
.text-center {
  text-align: center;
}
.capitalize {
  text-transform: capitalize;
}
html,
body {
  width: 100%;
  min-height: 100%;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 1rem;
  background: #f5f5f5;
  color: #201c23;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}
html {
  box-sizing: border-box;
  background: transparent;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
.global-padding {
  padding: 8px;
}
@media (min-width: 600px) {
  .global-padding {
    padding: 16px;
  }
}
@media (min-width: 900px) {
  .global-padding {
    padding: 24px;
  }
}
.paper__wrapper > * + * {
  margin-top: 10px;
}
hr {
  width: 100%;
  height: 1px;
  background: #ccc;
  border: none;
}
img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}
.body-lock {
  overflow: hidden;
  position: fixed;
}
.global-error-message {
  background: #f96763;
  background-image: repeating-linear-gradient(45deg, #f96763, #f96763 15px, #f95d59 15px, #f95d59 30px);
  color: #fff;
  padding: 20px 40px;
  display: flex;
  align-items: center;
}
.global-error-message span:not(.icon) {
  flex: 1;
  line-height: 1.3;
  font-weight: 700;
  margin: 0 30px;
}
.global-error-message .icon {
  width: 32px;
  height: 32px;
  color: #fff;
}
.global-error-message .icon svg {
  fill: currentColor;
}
.global-error-message button {
  flex: 0;
  text-align: right;
  margin: 0 0 0 30px;
}
.docs__row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}
.alert {
  display: inline-block;
  padding: 16px;
  font-weight: 700;
}
.alert--error {
  background: #e74c3c;
  color: #fff;
}
.alert--success {
  background: #2ecc71;
  color: #fff;
}
.alert--working {
  background: #ccc;
  color: #201c23;
}
button {
  display: inline-block;
  appearance: none;
  user-select: none;
  text-decoration: none;
  cursor: pointer;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 400;
  border: none;
  background-color: transparent;
  border-color: transparent;
  padding: 0;
  margin: 0;
}
.button {
  flex-shrink: 0;
  min-width: 124px;
  text-align: center;
  font-size: 18px;
  line-height: 24px;
  border-width: 2px;
  border-style: solid;
  border-radius: 4px;
  padding: 12px 24px;
  background-color: #4183d7;
  color: #fff;
  border-color: #4183d7;
  transition: all 100ms ease-out;
}
.button:hover {
  color: #fff;
}
.button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
.button:not([disabled]):hover {
  background: #2767b9;
  border-color: #2767b9;
}
.button:not([disabled]):active {
  background: #2561ad;
  border-color: #2561ad;
}
.button.button--plain {
  background-color: transparent;
  color: #4183d7;
  border-color: transparent;
}
.button.button--plain:not([disabled]):hover {
  background-color: transparent;
  color: #296ec5;
  border-color: transparent;
}
.button.button--plain:not([disabled]):active {
  background-color: transparent;
  color: #2561ad;
  border-color: transparent;
}
.button.button--outline {
  background-color: #fff;
  color: #4183d7;
}
.button.button--outline:not([disabled]):hover {
  background: #fff;
  color: #296ec5;
}
.button.button--outline:not([disabled]):active {
  color: #2561ad;
}
.button--secondary {
  flex-shrink: 0;
  min-width: 124px;
  text-align: center;
  font-size: 18px;
  line-height: 24px;
  border-width: 2px;
  border-style: solid;
  border-radius: 4px;
  padding: 12px 24px;
  background-color: #5a6879;
  color: #fff;
  border-color: #5a6879;
  transition: all 100ms ease-out;
}
.button--secondary:hover {
  color: #fff;
}
.button--secondary[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
.button--secondary:not([disabled]):hover {
  background: #485361;
  border-color: #485361;
}
.button--secondary:not([disabled]):active {
  background: #434e5b;
  border-color: #434e5b;
}
.button--secondary.button--plain {
  background-color: transparent;
  color: #5a6879;
  border-color: transparent;
}
.button--secondary.button--plain:not([disabled]):hover {
  background-color: transparent;
  color: #4c5867;
  border-color: transparent;
}
.button--secondary.button--plain:not([disabled]):active {
  background-color: transparent;
  color: #434e5b;
  border-color: transparent;
}
.button--secondary.button--outline {
  background-color: #fff;
  color: #5a6879;
}
.button--secondary.button--outline:not([disabled]):hover {
  background: #fff;
  color: #4c5867;
}
.button--secondary.button--outline:not([disabled]):active {
  color: #434e5b;
}
.button--red {
  background-color: #e74c3c;
  color: #fff;
  border-color: #e74c3c;
  transition: all 100ms ease-out;
}
.button--red:hover {
  color: #fff;
}
.button--red:not([disabled]):hover {
  background: #dc2d1b;
  border-color: #dc2d1b;
}
.button--red:not([disabled]):active {
  background: #c22818;
  border-color: #c22818;
}
.button--red.button--plain {
  background-color: transparent;
  color: #e74c3c;
  border-color: transparent;
}
.button--red.button--plain:not([disabled]):hover {
  background-color: transparent;
  color: #dc2d1b;
  border-color: transparent;
}
.button--red.button--plain:not([disabled]):active {
  background-color: transparent;
  color: #c22818;
  border-color: transparent;
}
.button--red.button--plain--reject {
  font-size: 14px;
  font-weight: 700;
  background-color: transparent;
  color: #f96763;
  border-color: transparent;
}
.button--red.button--plain--reject:not([disabled]):hover {
  background-color: transparent;
  color: #f73631;
  border-color: transparent;
}
.button--red.button--plain--reject:not([disabled]):active {
  background-color: transparent;
  color: #f6150f;
  border-color: transparent;
}
.button--red.button--outline {
  background-color: #fff;
  color: #e74c3c;
}
.button--red.button--outline:not([disabled]):hover {
  background: #fff;
  color: #dc2d1b;
}
.button--red.button--outline:not([disabled]):active {
  color: #c22818;
}
.button--full-width {
  display: block;
  width: 100%;
}
.button--icon {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.button--icon > * + * {
  margin-left: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.button--icon .icon {
  fill: #4183d7;
  margin-right: 5px;
}
.button--secondary--icon {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.button--secondary--icon > * + * {
  margin-left: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.button--secondary--icon .icon {
  fill: #5a6879;
  margin-right: 5px;
}
.button--small {
  min-width: 0;
  padding: 8px 24px;
}
.button--no-padding {
  padding: 0;
}
.switch__button {
  display: flex;
  align-items: center;
}
.text-link,
.DraftJSEditor-urlInputButton {
  color: #4183d7;
  font-size: 18px;
}
.DraftJSEditor-root button,
.DraftJSEditor-root .DraftJSEditor-urlInputButton {
  color: #4183d7;
  font-size: 18px;
}
.DraftJSEditor-root input {
  margin: 8px 0;
}
.button__children {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.button__children .spinner > div {
  background-color: #fff;
}
.button--loading .spinner > div {
  background-color: #fff;
}
.button--error {
  background-color: #e74c3c;
  border-color: transparent;
}
.button--error:not([disabled]):hover {
  background: #cf2b1a;
  border-color: transparent;
}
.button--success {
  background-color: #2ecc71;
  border-color: transparent;
}
.button--success .button__children {
  position: relative;
  padding-left: 32px;
}
.button--success:not([disabled]):hover {
  background: #25a35a;
  border-color: transparent;
}
.button--success svg {
  position: absolute;
  left: 0px;
}
.card-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  margin-top: 24px;
  margin-left: -4px;
  margin-right: -4px;
}
.card-list > * {
  margin-left: 4px;
  margin-right: 4px;
  margin-bottom: 24px;
}
@media (min-width: 600px) {
  .card-list {
    margin-left: -12px;
    margin-right: -12px;
  }
  .card-list > * {
    margin-left: 12px;
    margin-right: 12px;
  }
}
@media (min-width: 900px) {
  .card-list {
    justify-content: flex-start;
    margin-top: 0;
    margin-left: -20px;
    margin-right: -20px;
  }
  .card-list > * {
    margin-left: 20px;
    margin-right: 20px;
  }
}
.card-list--empty {
  text-align: center;
  padding: 48px 20px;
}
.card-list--empty .icon {
  width: 48px;
  height: 48px;
  fill: #ccc;
  margin: 0 auto;
}
.card-list--empty p {
  font-size: 22px;
  margin-top: 48px;
  margin-bottom: 40px;
}
.card {
  position: relative;
}
.card__link {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  width: 304px;
  height: 304px;
  background: #fff;
  border: 1px solid #ccc;
  flex-shrink: 0;
  transition: all 100ms ease-out;
}
.card__link[href]:hover {
  border-color: #4183d7;
  border-width: 2px;
}
.card__link:hover .card__content {
  background: #4183d7;
  border-color: #4183d7;
}
.card__link:hover .card__content--name {
  color: #fff;
}
.card__link:hover .card__content--subtitle {
  color: rgba(255,255,255,0.7);
}
.card__tags {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.card__tags > * {
  margin: 10px;
}
.card .menu {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 97;
}
.card__logo {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  width: 302px;
  height: 222px;
  font-size: 26px;
  padding: 40px 16px;
}
.card__logo > div {
  line-height: 1.2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.card__logo > img {
  max-width: 270px;
  max-height: 142px;
}
.card__content {
  width: 302px;
  height: 80px;
  padding: 16px 20px;
  border-top: 1px solid #ccc;
  transition: all 200ms ease-out;
}
.card__content--name {
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 6px;
  color: #446cb3;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.card__content--subtitle {
  color: rgba(32,28,35,0.7);
}
.card__image {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  width: 132px;
  height: 132px;
  border-radius: 100%;
  background: #ccc;
  color: #fff;
  margin-bottom: 24px;
  font-size: 60px;
  text-transform: capitalize;
}
.card__profile--name {
  text-align: center;
  font-size: 18px;
  line-height: 1.2;
  color: #201c23;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 12px;
}
.card__profile--subtitle {
  text-align: center;
  font-size: 14px;
  line-height: 1.2;
  color: rgba(32,28,35,0.7);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.card__profile--status {
  position: absolute;
  bottom: 8px;
  left: 0;
  width: 100%;
  color: #201c23;
  font-size: 14px;
  text-align: center;
  padding: 8px;
}
.card__profile--status__children {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}
.card__profile--status__children > .icon {
  width: 18px;
  height: 18px;
  margin-right: 4px;
}
.card--disabled .card__link {
  background: rgba(204,204,204,0.5);
}
.card--add {
  background: rgba(204,204,204,0.5);
  transition: all 100ms ease-out;
}
.card--add > div {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 24px;
}
.card--add .icon {
  width: 72px;
  height: 72px;
  fill: #4183d7;
}
.card--add .text {
  font-family: 'Roboto Condensed', sans-serif;
  color: #4183d7;
  margin-top: 24px;
}
.card--add:hover {
  background: #4183d7;
}
.card--add:hover .icon {
  fill: #fff;
}
.card--add:hover .text {
  color: #fff;
}
.confirmation {
  width: 100%;
  height: auto;
  text-align: center;
  padding: 16px 8px;
}
@media (min-width: 600px) {
  .confirmation {
    padding: 28px 12px;
  }
}
@media (min-width: 900px) {
  .confirmation {
    padding: 40px 16px;
  }
}
.confirmation__icon .icon {
  width: 48px;
  height: 48px;
  fill: #e74c3c;
  margin: 0 auto 48px;
}
.confirmation__warning-text {
  font-size: 18px;
  margin-bottom: 16px;
}
.confirmation__confirmation-text {
  font-size: 18px;
  margin-bottom: 40px;
}
.confirmation__confirmation-text strong {
  color: #e74c3c;
}
.confirmation .button + .button {
  margin-left: 24px;
}
.contact-calendar__wrapper {
  width: 100%;
  height: auto;
  overflow-x: auto;
  overflow-y: hidden;
  border: 1px solid #ccc;
}
.contact-calendar {
  display: flex;
  flex-direction: column;
}
.contact-calendar__cell {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 0;
  min-width: 100px;
  background: #fff;
  padding: 4px;
  border: 1px solid #ccc;
}
.contact-calendar__header {
  display: flex;
  width: 100%;
  height: auto;
}
.contact-calendar__header--contact {
  width: 180px;
  font-size: 14px;
  font-weight: 700;
  color: #626065;
  justify-content: flex-start;
}
.contact-calendar__header--name {
  font-size: 14px;
  font-weight: 700;
  color: #626065;
  text-align: center;
  background-color: #f4f7fa;
}
.contact-calendar__header--date {
  min-height: 48px;
  font-size: 16px;
  color: #626065;
  padding: 20px;
}
.contact-calendar__row {
  display: flex;
  width: 100%;
  height: auto;
  min-height: 100px;
}
.contact-calendar__contact {
  width: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
}
.contact-calendar__contact .contact--name {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 8px;
}
.contact-calendar__contact .contact--name .name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.contact-calendar__contact .contact--name .icon {
  fill: #ccc;
}
.contact-calendar__contact .contact--credential-count {
  color: #626065;
  margin-bottom: 4px;
}
.contact-calendar__day {
  background: #fff;
}
.contact-calendar__day--empty {
  background-image: repeating-linear-gradient(135deg, transparent, transparent 20px, #fafbfd 20px, #fafbfd 30px);
}
.contact-calender__credential {
  color: #3e3942;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  border-left-width: 4px;
  border-left-style: solid;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  padding: 6px;
}
.pending-tooltip {
  text-transform: uppercase;
  font-weight: 700;
}
.calendar-filter-menu {
  position: absolute;
  background: #fff;
  border: 1px solid #ccc;
  padding: 8px 8px 8px 16px;
  z-index: 2;
}
.calendar-filter-menu:before,
.calendar-filter-menu:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #bcbcbc;
  position: absolute;
  top: -10px;
  left: 27px;
}
.calendar-filter-menu:after {
  border-bottom-color: #fff;
  top: -9px;
}
.generic-form__body {
  padding: 20px;
}
.generic-form__body > * + * {
  margin-top: 16px;
}
@media (min-width: 600px) {
  .generic-form__body > * + * {
    margin-top: 24px;
  }
}
@media (min-width: 900px) {
  .generic-form__body > * + * {
    margin-top: 32px;
  }
}
.generic-form__body.flex {
  display: flex;
  flex-direction: row;
}
.generic-form__body.flex .flex-column {
  flex: 1;
  flex-direction: column;
}
.generic-form__body.flex .toggle-group__parent {
  flex: 1;
  margin-top: 0;
  padding: 0;
}
.generic-form__body.flex .toggle-group__item--description {
  display: flex;
  justify-content: flex-end;
}
.generic-form__body.flex .toggle-group__item--toggle {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.generic-form__footer {
  padding: 20px;
  border-top: 1px solid #ccc;
  margin-top: -1px;
  margin-bottom: -1px;
}
.generic-form__footer .button + .button {
  margin-left: 16px;
}
.input-group > * + * {
  margin-top: 16px;
}
@media (min-width: 600px) {
  .input-group > * + * {
    margin-top: 24px;
  }
}
@media (min-width: 900px) {
  .input-group > * + * {
    margin-top: 32px;
  }
}
.input-group--small {
  max-width: 350px;
}
.input-group--large {
  max-width: 700px;
}
@media (min-width: 600px) {
  .input-group--multidate {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 100%;
  }
  .input-group--multidate > * {
    max-width: 200px;
  }
  .input-group--multidate > * + * {
    margin-top: 0;
    margin-left: 32px;
  }
}
.fileinput--with-values {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.fileinput--with-values input[type='text'] {
  width: auto;
  margin: 16px 0;
}
@media (min-width: 600px) {
  .fileinput--with-values {
    flex-wrap: nowrap;
  }
  .fileinput--with-values input[type='text'] {
    margin: 0;
  }
}
.form--inline {
  display: flex;
  align-items: center;
}
.form--inline .input input {
  padding: 4px 12px;
}
.form--inline > * + * {
  margin-left: 8px;
}
.quantity-field {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.quantity-field .toggle-scaffold {
  width: 48px;
}
.quantity-field .toggle-scaffold .toggle {
  margin-left: 0;
}
.quantity-field .toggle-scaffold .toggle-icon {
  padding: 8px;
}
.quantity-field input {
  width: 48px !important;
  padding: 8px 2px !important;
  text-align: center;
}
.quantity-field button:focus {
  outline: none;
}
.quantity-button,
.quantity-button__spacer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 48px;
}
.quantity-button .icon,
.quantity-button__spacer .icon {
  fill: #626065;
}
.input-scaffold {
  position: relative;
  display: block;
  width: 100%;
/* Specific input type styles */
/* Focus styles */
/* Disabled styles */
}
.input-scaffold label {
  cursor: pointer;
}
.input-scaffold .input-label {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 6px;
}
.input-scaffold .input-label--with-icon {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}
.input-scaffold .input-label--with-icon .icon {
  margin-right: 8px;
}
.input-scaffold .input-label--optional {
  margin-left: 10px;
}
.input-scaffold .input-label--label p {
  font-weight: normal;
  font-size: 16px;
  opacity: 0.7;
  margin-top: 3px;
}
.input-scaffold .input-validation {
  display: inline-block;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  color: #e74c3c;
  background: #fff;
  border: 1px solid #e74c3c;
  border-radius: 2px;
  padding: 4px 8px;
  position: absolute;
  z-index: 100;
  box-shadow: 0 4px 8px rgba(0,0,0,0.3);
}
.input-scaffold input:not(.DateInput_input):not(.react-select__input),
.input-scaffold textarea,
.input-scaffold select,
.input-scaffold div.parsed-html {
  display: block;
  width: 100%;
  color: inherit;
  font-family: inherit;
  font-size: 18px;
  line-height: 24px;
  border: 2px solid #ccc;
  border-radius: 2px;
  padding: 8px 12px;
  cursor: default;
  appearance: none;
}
.input-scaffold textarea {
  min-height: 120px;
  resize: vertical;
}
.input-scaffold select {
  background: none;
  background-repeat: no-repeat;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAABYUlEQVRoge2YMU7EMBBF3yIuhFJyARo6aKHOCTYFHQU5AUegouMaEZehQ1pBgSMi5Dgz9hizYl4VxfbkP8lx7Oy6ruMYmKYpev/kl3OY4wKtcYHWuEBrXKA1LtAaF2hNjsCZeYqC2lqBHngFBu2DBAyhdq8ZpBHogcdw/YCtxBBqEp4hlpAKLMPPWEksw8+IJSQCO+Bipa1UIhZ+5lxSQCLwAVwDLyvtuRKp8E/AraSIdAq9A1ekJe6FtQh9U+FvgIOkkOYl3pK4A0ZBnTH0jaEKD/pldEtiT1piDH1iqMND3ocsV8I8PORvJbQSVcIDnOYMCswSz8BlpH2/cr2kKDyUCYBO4ifF4cFmN7o1nWKYhAe77bRGwiw82J4HJBKm4cH+QJOSMA8PdU5kMYkq4aF8FVpjuTq9USk81BOAb4kDlcJDXQH4kqjKv/wr8adwgda4QGtcoDUu0BoXaM3RC3wCkn5bVLDSEjUAAAAASUVORK5CYII=");
  background-size: 24px 24px;
  background-position: right 12px center;
  padding: 8px 36px 8px 12px;
  cursor: inherit;
}
.input-scaffold select::-ms-expand {
  display: none;
}
.input-scaffold input:focus:not(.DateInput_input):not(.react-select__input),
.input-scaffold textarea:focus,
.input-scaffold select:focus {
  outline: none;
  border-color: #4183d7;
  box-shadow: 0px 0px 0px 2px rgba(65,131,215,0.5);
}
.input-scaffold .input--disabled,
.input-scaffold .input--disabled > * {
  cursor: not-allowed !important;
  opacity: 0.65;
  pointer-events: none;
}
.input-scaffold input[disabled],
.input-scaffold textarea[disabled],
.input-scaffold select[disabled] {
  background-color: #f5f5f5;
}
.inline-inputs {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
.inline-inputs > * + * {
  margin-left: 24px;
}
.inline-inputs .input-scaffold {
  width: 180px;
}
.inline-inputs .timepicker {
  display: inline-block;
}
.inline-checkbox {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
}
.inline-checkbox .input {
  display: flex;
  align-items: center;
}
.inline-checkbox .input .input-label {
  margin: 0;
}
.fw-100 {
  width: 100px;
}
.fw-150 {
  width: 150px;
}
.fw-200 {
  width: 200px;
}
.fw-250 {
  width: 250px;
}
.fw-300 {
  width: 300px;
}
.fw-350 {
  width: 350px;
}
.fw-400 {
  width: 400px;
}
.fw-450 {
  width: 450px;
}
.fw-500 {
  width: 500px;
}
.fw-550 {
  width: 550px;
}
.fw-600 {
  width: 600px;
}
.fw-650 {
  width: 650px;
}
.fw-700 {
  width: 700px;
}
.cropper-scaffold .input-label {
  cursor: default;
}
.cropper-scaffold .original-image {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 340px;
  border: 2px dashed #ccc;
  padding: 8px;
}
.cropper-scaffold .cropper-scaffold__controls {
  width: 100%;
  text-align: center;
  margin-top: 16px;
}
.form-builder__field {
  padding: 20px;
}
.form-builder__field > * + * {
  margin-top: 16px;
}
@media (min-width: 600px) {
  .form-builder__field > * + * {
    margin-top: 24px;
  }
}
@media (min-width: 900px) {
  .form-builder__field > * + * {
    margin-top: 32px;
  }
}
.form-builder__field + .form-builder__field {
  border-top: 1px solid #ccc;
  margin-top: 20px;
}
.form-builder__field h3 {
  margin-top: 20px;
}
.form-builder__field--add .input-scaffold {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.custom-request-form__field--title h3 {
  display: inline;
  margin: 0;
  padding: 0;
  position: relative;
  top: -3px;
}
.custom-request-form__field--title .button {
  padding: 0px;
  margin-left: 20px;
  color: #e74c3c;
}
.custom-request-form__field--title .button:not([disabled]):hover {
  color: #e32f1d;
}
.custom-request-form__field--title .button span {
  margin-left: 4px;
}
.custom-request-form__field--title .button svg {
  fill: currentColor;
}
.array-input {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}
.array-input__remove {
  padding: 8px;
}
.array-input__remove .icon {
  fill: #201c23;
}
.array-input__add {
  padding-left: 0;
  padding-right: 0;
  min-width: 0;
}
.SingleDatePickerInput {
  display: inline-flex;
  border: 2px solid #ccc;
  border-radius: 2px;
  width: 100%;
  justify-content: space-between;
}
.SingleDatePickerInput_calendarIcon {
  padding: 0px 7px 0px 0px;
}
.SingleDatePickerInput_clearDate {
  right: 30px;
  padding: 5px;
}
.SingleDatePickerInput__showClearDate {
  padding: 0;
}
.DateInput_input {
  font-family: inherit;
  font-size: 18px;
  border-bottom: 0;
  padding: 4px 4px 4px 8px;
  margin: 4px;
}
.DateInput_input__focused {
  background: #99ede6;
  border-color: #99ede6;
  border-radius: 3px;
  color: #007a87;
}
.DayPicker .DayPicker_weekHeader_ul {
  font: inherit;
}
.CalendarMonth .CalendarMonth_caption {
  padding-bottom: 47px;
}
.CalendarDay {
  font: inherit;
  vertical-align: middle;
}
.SideTimeDisplay {
  display: flex;
  white-space: nowrap;
}
.timezone {
  margin: 13px;
  font-size: 18px;
}
.timepicker-container {
  position: relative;
}
.timepicker-clear {
  position: absolute;
  top: 10px;
  right: 50px;
  cursor: pointer;
  overflow: hidden;
}
.timepicker-clear-icon:after {
  content: "×";
  font-size: 24px;
  color: #aaa;
  transition: color 0.3s ease;
}
.timepicker-clear-icon:hover:after {
  color: #666;
}
.timepicker-input {
  display: block;
  width: 100%;
  position: relative;
  background: none;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20d%3D%22M11.99%202C6.47%202%202%206.48%202%2012s4.47%2010%209.99%2010C17.52%2022%2022%2017.52%2022%2012S17.52%202%2011.99%202zM12%2020c-4.42%200-8-3.58-8-8s3.58-8%208-8%208%203.58%208%208-3.58%208-8%208zm.5-13H11v6l5.25%203.15.75-1.23-4.5-2.67z%22%2F%3E%3C%2Fsvg%3E");
  background-size: 24px 24px;
  background-position: right 12px center;
}
.timepicker-panel {
  z-index: 1070;
  width: 170px;
  position: absolute;
  box-sizing: border-box;
}
.timepicker-panel * {
  box-sizing: border-box;
}
.timepicker-panel-inner {
  display: inline-block;
  position: relative;
  outline: none;
  list-style: none;
  font-size: 12px;
  text-align: left;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 1px 5px #ccc;
  background-clip: padding-box;
  border: 1px solid #ccc;
  line-height: 1.5;
}
.timepicker-panel-input {
  font-size: 16px;
  font-family: inherit;
  margin: 0;
  padding: 0;
  width: 100%;
  cursor: auto;
  line-height: 1.5;
  outline: 0;
  border: 1px solid transparent;
}
.timepicker-panel-input-wrap {
  box-sizing: border-box;
  position: relative;
  padding: 6px;
  border-bottom: 1px solid #e9e9e9;
}
.timepicker-panel-input-invalid {
  border-color: #f00;
}
.timepicker-panel-select {
  float: left;
  font-size: 16px;
  border: 1px solid #e9e9e9;
  border-width: 0 1px;
  margin-left: -1px;
  box-sizing: border-box;
  width: 56px;
  overflow: hidden;
  position: relative;
}
.timepicker-panel-select-active {
  overflow-y: auto;
}
.timepicker-panel-select:first-child {
  border-left: 0;
  margin-left: 0;
}
.timepicker-panel-select:last-child {
  border-right: 0;
}
.timepicker-panel-select ul {
  list-style: none;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  width: 100%;
  max-height: 144px;
}
.timepicker-panel-select li {
  list-style: none;
  box-sizing: content-box;
  margin: 0;
  padding: 0 0 0 16px;
  width: 100%;
  height: 24px;
  line-height: 24px;
  text-align: left;
  cursor: pointer;
  user-select: none;
}
.timepicker-panel-select li:hover {
  background: #edfaff;
}
li.timepicker-panel-select-option-selected {
  background: #edfaff;
  color: #2db7f5;
}
li.timepicker-panel-select-option-disabled {
  color: #bfbfbf;
}
li.timepicker-panel-select-option-disabled:hover {
  background: transparent;
  cursor: not-allowed;
}
.color-picker {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.color-picker__color {
  display: block;
  width: 36px;
  height: 36px;
  background: #ccc;
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 2px;
  margin-right: 6px;
  margin-bottom: 6px;
}
.color-picker__color:last-child {
  margin-right: 0;
}
.color-picker__color:focus {
  outline: none;
  border-color: #4183d7;
  box-shadow: 0px 0px 0px 2px rgba(65,131,215,0.5);
}
.color-picker__color--active {
  border-color: #201c23;
}
.toggle-scaffold {
  position: relative;
/* Label */
/* Icon */
/* Icon ripple animation */
/* Focus styles */
/* Disabled styles */
}
.toggle-scaffold label {
  cursor: pointer;
}
.toggle-scaffold .toggle {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: -12px;
}
.toggle-scaffold .toggle-label {
  font-size: 18px;
  font-weight: 400;
  padding-top: 12px;
  padding-right: 12px;
  padding-bottom: 12px;
}
.toggle-scaffold .toggle-icon {
  display: block;
  position: relative;
  overflow: hidden;
  width: 48px;
  height: 48px;
  border-radius: 100%;
  padding: 12px;
  flex-shrink: 0;
}
.toggle-scaffold .toggle-icon svg {
  fill: #ccc;
}
.toggle-scaffold .toggle-icon.toggle-icon--checked svg {
  fill: #4183d7;
}
.toggle-scaffold .toggle-icon:after {
  content: "";
  display: block;
  position: absolute;
  width: 48px;
  height: 48px;
  border-radius: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background-color: #4183d7;
  transform: scale(0, 0);
  opacity: 0.35;
  transition-property: transform, opacity;
  transition-timing-function: ease-out;
  transition-duration: 0s;
}
.toggle-scaffold .toggle-icon.toggle-icon--checked:after {
  transform: scale(1, 1);
  opacity: 0;
  transition-duration: 500ms;
}
.toggle-scaffold .toggle:focus {
  outline: none;
}
.toggle-scaffold .toggle:not(.toggle--disabled):focus .toggle-icon--checkbox svg {
  border-radius: 5px;
  box-shadow: inset 0px 0px 0px 3px rgba(65,131,215,0.5);
}
.toggle-scaffold .toggle:not(.toggle--disabled):focus .toggle-icon--radio svg {
  border-radius: 100%;
  box-shadow: inset 0px 0px 0px 2px rgba(65,131,215,0.5);
}
.toggle-scaffold .toggle--disabled {
  cursor: not-allowed !important;
  opacity: 0.65;
}
.toggle-scaffold.toggle--switch .toggle-label {
  font-weight: bold;
  order: -1;
  flex-grow: 1;
}
.toggle-scaffold.toggle--switch .toggle {
  width: 100%;
  margin-left: 0;
}
.toggle-scaffold.toggle--switch .toggle-icon {
  width: 74px;
}
.toggle-scaffold.toggle--switch .toggle-icon:after {
  display: none;
}
.checkboxes-horizontal {
  display: flex;
  white-space: nowrap;
  flex-wrap: wrap;
}
.checkboxes-horizontal .toggle-scaffold {
  margin-right: 30px;
}
.ReactTable {
  position: relative;
}
.ReactTable .rt-table {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  border-collapse: collapse;
  overflow: auto;
}
.ReactTable .rt-thead {
  display: flex;
  flex-direction: column;
  user-select: none;
  font-size: 18px;
  line-height: 22px;
  background: #201c23;
}
.ReactTable .rt-thead.-headerGroups {
  background: #201c23;
}
.ReactTable .rt-td {
  color: #626065;
  font-size: 14px;
}
.ReactTable .rt-td:first-of-type {
  color: #201c23;
  font-size: 18px;
}
.ReactTable .rt-thead .rt-th {
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: left;
  padding: 14px 12px;
}
.ReactTable .rt-thead.-filters .rt-th {
  padding: 0 20px 14px;
  margin-top: -4px;
}
.ReactTable .rt-thead .rt-th {
  line-height: normal;
  position: relative;
}
.ReactTable .rt-thead .rt-th.-sort-asc .rt-resizable-header-content:after,
.ReactTable .rt-thead .rt-td.-sort-asc .rt-resizable-header-content:after {
  content: url("data:image/svg+xml; utf8, <svg width='100%' height='100%' viewBox='0 0 1792 1792' xmlns='http://www.w3.org/2000/svg'><path d='M1408 704q0 26-19 45l-448 448q-19 19-45 19t-45-19l-448-448q-19-19-19-45t19-45 45-19h896q26 0 45 19t19 45z' fill='#fff'/></svg>");
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-left: 8px;
  flex-shrink: 0;
}
.ReactTable .rt-thead .rt-th.-sort-desc .rt-resizable-header-content:after,
.ReactTable .rt-thead .rt-td.-sort-desc .rt-resizable-header-content:after {
  content: url("data:image/svg+xml; utf8, <svg width='100%' height='100%' viewBox='0 0 1792 1792' xmlns='http://www.w3.org/2000/svg'><path d='M1408 1216q0 26-19 45t-45 19h-896q-26 0-45-19t-19-45 19-45l448-448q19-19 45-19t45 19l448 448q19 19 19 45z' fill='#fff'/></svg>");
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-left: 8px;
  flex-shrink: 0;
}
.ReactTable .rt-thead .rt-th.-cursor-pointer,
.ReactTable .rt-thead .rt-td.-cursor-pointer {
  cursor: pointer;
}
.ReactTable .rt-thead .rt-th:last-child,
.ReactTable .rt-thead .rt-td:last-child {
  border-right: 0;
}
.ReactTable .rt-thead .rt-resizable-header {
  overflow: visible;
}
.ReactTable .rt-thead .rt-resizable-header:last-child {
  overflow: hidden;
}
.ReactTable .rt-thead .rt-resizable-header-content {
  display: flex;
  align-items: center;
  white-space: pre-wrap;
}
.ReactTable .rt-tbody {
  display: flex;
  flex-direction: column;
}
.ReactTable .rt-tbody .rt-tr-group {
  border-top: 1px solid #ccc;
}
.ReactTable .rt-tbody .rt-tr-group.locked {
  color: rgba(32,28,35,0.5);
}
.ReactTable .rt-tbody .rt-tr-group:not(.no-actions) {
  cursor: pointer;
  transition: all 300ms ease-out;
}
.ReactTable .rt-tbody .rt-tr-group:not(.no-actions):hover {
  background: rgba(65,131,215,0.05);
  box-shadow: inset 0 0 0 1px #6bb9f0;
}
.ReactTable .rt-tbody .rt-tr-group:not(.no-actions):hover .rt-td.action--animate {
  opacity: 1;
  transform: translateX(0);
}
.ReactTable .rt-tbody .rt-pivot {
  cursor: pointer;
}
.ReactTable .rt-tr-group {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.ReactTable .rt-tr {
  display: inline-flex;
}
.ReactTable .rt-th,
.ReactTable .rt-td {
  flex: 1 0 0;
  display: flex;
  align-items: center;
  font-size: 16px;
  padding: 14px 12px;
  transition: 0.3s ease;
  transition-property: all;
}
.ReactTable .rt-td.action {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 12px 12px;
}
.ReactTable .rt-td.action .icon {
  fill: #4183d7;
}
.ReactTable .rt-td.action a,
.ReactTable .rt-td.action button {
  display: flex;
}
.ReactTable .rt-td.action--animate {
  opacity: 0;
  transform: translateX(-8px);
}
.ReactTable .rt-th.-hidden,
.ReactTable .rt-td.-hidden {
  width: 0 !important;
  min-width: 0 !important;
  padding: 0 !important;
  border: 0 !important;
  opacity: 0 !important;
}
.ReactTable .rt-expander {
  display: inline-block;
  position: relative;
  margin: 0;
  color: transparent;
  margin: 0 10px;
}
.ReactTable .rt-expander:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-90deg);
  border-left: 5.04px solid transparent;
  border-right: 5.04px solid transparent;
  border-top: 7px solid rgba(0,0,0,0.8);
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  cursor: pointer;
}
.ReactTable .rt-expander.-open:after {
  transform: translate(-50%, -50%) rotate(0);
}
.ReactTable .rt-resizer {
  display: inline-block;
  position: absolute;
  width: 36px;
  top: 0;
  bottom: 0;
  right: -18px;
  cursor: col-resize;
  z-index: 10;
}
.ReactTable .rt-tfoot {
  display: flex;
  flex-direction: column;
}
.ReactTable .-pagination {
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  padding: 4px;
  border-top: 1px solid #ccc;
}
.ReactTable .-pagination .-btn {
  appearance: none;
  display: block;
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 3px;
  padding: 6px;
  font-size: 1em;
  color: rgba(0,0,0,0.6);
  background: #ccc;
  transition: all 0.1s ease;
  cursor: pointer;
  outline: none;
}
.ReactTable .-pagination .-btn[disabled] {
  opacity: 0.5;
  cursor: default;
}
.ReactTable .-pagination .-btn:not([disabled]):hover {
  background: #ccc;
  color: #fff;
}
.ReactTable .-pagination .-previous,
.ReactTable .-pagination .-next {
  flex: 1;
  text-align: center;
}
.ReactTable .-pagination .-center {
  flex: 1.5;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  margin-bottom: 0;
}
.ReactTable .-pagination .-pageInfo {
  display: inline-block;
  margin: 3px 10px;
  white-space: nowrap;
}
.ReactTable .-pagination .-pageJump {
  display: inline-block;
}
.ReactTable .-pagination .-pageJump input {
  width: 70px;
  text-align: center;
}
.ReactTable .-pagination .-pageSizeOptions {
  margin: 3px 10px;
}
.ReactTable .rt-noData {
  display: block;
  transition: all 0.3s ease;
  z-index: 1;
  pointer-events: none;
  padding: 20px;
}
.ReactTable .-loading {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255,255,255,0.8);
  transition: all 0.3s ease;
  z-index: 2;
  opacity: 0;
  pointer-events: none;
}
.ReactTable .-loading > div {
  position: absolute;
  display: block;
  text-align: center;
  width: 100%;
  top: 50%;
  left: 0;
  font-size: 15px;
  color: rgba(0,0,0,0.6);
  transform: translateY(-52%);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.ReactTable .-loading.-active {
  opacity: 1;
  pointer-events: all;
}
.ReactTable .-loading.-active > div {
  transform: translateY(50%);
}
.ReactTable .rt-thead input,
.ReactTable .rt-thead select {
  width: 100%;
  max-width: 320px;
  appearance: none;
  border: 1px solid #ccc;
  border-radius: 0;
  background: #fff;
  padding: 4px 8px;
  font-family: inherit;
  font-size: inherit;
  font-weight: normal;
  outline: none;
}
.ReactTable .rt-thead select {
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%' viewBox='0 0 24 24'><path d='M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z' fill='#322D35'/></svg>");
  background-size: 18px 18px;
  background-position: right 8px center;
  padding: 4px 26px 4px 8px;
  cursor: inherit;
}
.ReactTable .rt-thead select::-ms-expand {
  display: none;
}
.ReactTable .rt-thead select:invalid {
  color: rgba(32,28,35,0.5);
}
.ReactTable .select-wrap {
  position: relative;
  display: inline-block;
}
.ReactTable .select-wrap select {
  padding: 5px 15px 5px 7px;
  min-width: 100px;
}
.ReactTable .select-wrap:after {
  content: '';
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translate(0, -50%);
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 2.5px;
}
.flex-cell {
  display: flex;
  align-items: center;
  width: 100%;
}
.flex-cell .input-scaffold {
  width: auto;
  flex: 0;
}
.flex-cell .input-scaffold + .button {
  margin-left: 6px;
}
.expandable-row__expanded-content .rt-thead {
  background-color: #ccc;
}
.expandable-row__expanded-content .rt-thead .rt-th {
  padding: 10px 12px;
  color: #322d35;
}
.expandable-row__expanded-content .ReactTable .rt-thead .rt-th.-sort-asc .rt-resizable-header-content:after,
.ReactTable .rt-thead .rt-td.-sort-asc .rt-resizable-header-content:after {
  content: url("data:image/svg+xml; utf8, <svg width='100%' height='100%' viewBox='0 0 1792 1792' xmlns='http://www.w3.org/2000/svg'><path d='M1408 704q0 26-19 45l-448 448q-19 19-45 19t-45-19l-448-448q-19-19-19-45t19-45 45-19h896q26 0 45 19t19 45z' fill='#322D35'/></svg>");
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-left: 8px;
  flex-shrink: 0;
}
.expandable-row__expanded-content .ReactTable .rt-thead .rt-th.-sort-desc .rt-resizable-header-content:after,
.ReactTable .rt-thead .rt-td.-sort-desc .rt-resizable-header-content:after {
  content: url("data:image/svg+xml; utf8, <svg width='100%' height='100%' viewBox='0 0 1792 1792' xmlns='http://www.w3.org/2000/svg'><path d='M1408 1216q0 26-19 45t-45 19h-896q-26 0-45-19t-19-45 19-45l448-448q19-19 45-19t45 19l448 448q19 19 19 45z' fill='#322D35'/></svg>");
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-left: 8px;
  flex-shrink: 0;
}
.expandable-row__expanded-content .ReactTable .rt-td {
  background-color: #f4f7fa;
}
.flex-end {
  justify-content: flex-end;
}
.spacer > * + * {
  margin-left: 8px;
}
.ReactTable {
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0,0,0,0.1);
}
.ReactTable * {
  box-sizing: border-box;
}
.ReactTable .rt-table {
  flex: auto 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  border-collapse: collapse;
  overflow: auto;
}
.ReactTable .rt-thead {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ReactTable .rt-thead.-headerGroups {
  background: rgba(0,0,0,0.03);
  border-bottom: 1px solid rgba(0,0,0,0.05);
}
.ReactTable .rt-thead.-filters {
  border-bottom: 1px solid rgba(0,0,0,0.05);
}
.ReactTable .rt-thead.-filters input,
.ReactTable .rt-thead.-filters select {
  border: 1px solid rgba(0,0,0,0.1);
  background: #fff;
  padding: 5px 7px;
  font-size: inherit;
  border-radius: 3px;
  font-weight: normal;
  outline: none;
}
.ReactTable .rt-thead.-filters .rt-th {
  border-right: 1px solid rgba(0,0,0,0.02);
}
.ReactTable .rt-thead.-header {
  box-shadow: 0 2px 15px 0px rgba(0,0,0,0.15);
}
.ReactTable .rt-thead .rt-tr {
  text-align: center;
}
.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
  padding: 5px 5px;
  line-height: normal;
  position: relative;
  border-right: 1px solid rgba(0,0,0,0.05);
  transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
}
.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: inset 0 3px 0 0 rgba(0,0,0,0.6);
}
.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: inset 0 -3px 0 0 rgba(0,0,0,0.6);
}
.ReactTable .rt-thead .rt-th.-cursor-pointer,
.ReactTable .rt-thead .rt-td.-cursor-pointer {
  cursor: pointer;
}
.ReactTable .rt-thead .rt-th:last-child,
.ReactTable .rt-thead .rt-td:last-child {
  border-right: 0;
}
.ReactTable .rt-thead .rt-resizable-header {
  overflow: visible;
}
.ReactTable .rt-thead .rt-resizable-header:last-child {
  overflow: hidden;
}
.ReactTable .rt-thead .rt-resizable-header-content {
  overflow: hidden;
  text-overflow: ellipsis;
}
.ReactTable .rt-thead .rt-header-pivot {
  border-right-color: #f7f7f7;
}
.ReactTable .rt-thead .rt-header-pivot:after,
.ReactTable .rt-thead .rt-header-pivot:before {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.ReactTable .rt-thead .rt-header-pivot:after {
  border-color: rgba(255,255,255,0);
  border-left-color: #fff;
  border-width: 8px;
  margin-top: -8px;
}
.ReactTable .rt-thead .rt-header-pivot:before {
  border-color: rgba(102,102,102,0);
  border-left-color: #f7f7f7;
  border-width: 10px;
  margin-top: -10px;
}
.ReactTable .rt-tbody {
  flex: 99999 1 auto;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.ReactTable .rt-tbody .rt-tr-group {
  border-bottom: solid 1px rgba(0,0,0,0.05);
}
.ReactTable .rt-tbody .rt-tr-group:last-child {
  border-bottom: 0;
}
.ReactTable .rt-tbody .rt-td {
  border-right: 1px solid rgba(0,0,0,0.02);
}
.ReactTable .rt-tbody .rt-td:last-child {
  border-right: 0;
}
.ReactTable .rt-tbody .rt-expandable {
  cursor: pointer;
  text-overflow: clip;
}
.ReactTable .rt-tr-group {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.ReactTable .rt-tr {
  flex: 1 0 auto;
  display: inline-flex;
}
.ReactTable .rt-th,
.ReactTable .rt-td {
  flex: 1 0 0px;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 7px 5px;
  overflow: hidden;
  transition: 0.3s ease;
  transition-property: width, min-width, padding, opacity;
}
.ReactTable .rt-th.-hidden,
.ReactTable .rt-td.-hidden {
  width: 0 !important;
  min-width: 0 !important;
  padding: 0 !important;
  border: 0 !important;
  opacity: 0 !important;
}
.ReactTable .rt-expander {
  display: inline-block;
  position: relative;
  margin: 0;
  color: transparent;
  margin: 0 10px;
}
.ReactTable .rt-expander:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-90deg);
  border-left: 5.04px solid transparent;
  border-right: 5.04px solid transparent;
  border-top: 7px solid rgba(0,0,0,0.8);
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  cursor: pointer;
}
.ReactTable .rt-expander.-open:after {
  transform: translate(-50%, -50%) rotate(0deg);
}
.ReactTable .rt-resizer {
  display: inline-block;
  position: absolute;
  width: 36px;
  top: 0;
  bottom: 0;
  right: -18px;
  cursor: col-resize;
  z-index: 10;
}
.ReactTable .rt-tfoot {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0px 15px 0px rgba(0,0,0,0.15);
}
.ReactTable .rt-tfoot .rt-td {
  border-right: 1px solid rgba(0,0,0,0.05);
}
.ReactTable .rt-tfoot .rt-td:last-child {
  border-right: 0;
}
.ReactTable.-striped .rt-tr.-odd {
  background: rgba(0,0,0,0.03);
}
.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
  background: rgba(0,0,0,0.05);
}
.ReactTable .-pagination {
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  padding: 3px;
  box-shadow: 0 0px 15px 0px rgba(0,0,0,0.1);
  border-top: 2px solid rgba(0,0,0,0.1);
}
.ReactTable .-pagination input,
.ReactTable .-pagination select {
  border: 1px solid rgba(0,0,0,0.1);
  background: #fff;
  padding: 5px 7px;
  font-size: inherit;
  border-radius: 3px;
  font-weight: normal;
  outline: none;
}
.ReactTable .-pagination .-btn {
  appearance: none;
  display: block;
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 3px;
  padding: 6px;
  font-size: 1em;
  color: rgba(0,0,0,0.6);
  background: rgba(0,0,0,0.1);
  transition: all 0.1s ease;
  cursor: pointer;
  outline: none;
}
.ReactTable .-pagination .-btn[disabled] {
  opacity: 0.5;
  cursor: default;
}
.ReactTable .-pagination .-btn:not([disabled]):hover {
  background: rgba(0,0,0,0.3);
  color: #fff;
}
.ReactTable .-pagination .-previous,
.ReactTable .-pagination .-next {
  flex: 1;
  text-align: center;
}
.ReactTable .-pagination .-center {
  flex: 1.5;
  text-align: center;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}
.ReactTable .-pagination .-pageInfo {
  display: inline-block;
  margin: 3px 10px;
  white-space: nowrap;
}
.ReactTable .-pagination .-pageJump {
  display: inline-block;
}
.ReactTable .-pagination .-pageJump input {
  width: 70px;
  text-align: center;
}
.ReactTable .-pagination .-pageSizeOptions {
  margin: 3px 10px;
}
.ReactTable .rt-noData {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255,255,255,0.8);
  transition: all 0.3s ease;
  z-index: 1;
  pointer-events: none;
  padding: 20px;
  color: rgba(0,0,0,0.5);
}
.ReactTable .-loading {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255,255,255,0.8);
  transition: all 0.3s ease;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
}
.ReactTable .-loading > div {
  position: absolute;
  display: block;
  text-align: center;
  width: 100%;
  top: 50%;
  left: 0;
  font-size: 15px;
  color: rgba(0,0,0,0.6);
  transform: translateY(-52%);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.ReactTable .-loading.-active {
  opacity: 1;
  z-index: 2;
  pointer-events: all;
}
.ReactTable .-loading.-active > div {
  transform: translateY(50%);
}
.ReactTable .rt-resizing .rt-th,
.ReactTable .rt-resizing .rt-td {
  transition: none !important;
  cursor: col-resize;
  user-select: none;
}
.generic-list__body > div {
  background: #fff;
  margin: 10px 0;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
.generic-list__item {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  line-height: 22px;
  padding: 14px 20px;
  cursor: pointer;
  background: #fff;
  margin: 0;
  border-top: 1px solid #ddd;
}
.generic-list__item:hover {
  background: rgba(65,131,215,0.05);
  box-shadow: inset 0 0 0 1px #6bb9f0;
}
.generic-list__item .content {
  font-size: 20px;
  font-weight: 700;
  color: #201c23;
  margin-right: auto;
}
.generic-list__item .action {
  margin-left: 16px;
}
.generic-list__item .action .icon {
  transform: rotate(180deg);
}
.generic-list__item--link {
  transition: all 300ms ease-in-out;
}
.generic-list__item--link .action {
  opacity: 0;
  transform: translateX(-8px);
  transition: all 300ms ease-in-out;
}
.generic-list__item--link:hover .action {
  opacity: 1;
  transform: translateX(0);
}
.accordion-toggle {
  display: flex;
  font-size: 18px;
  color: rgba(32,28,35,0.5);
}
.accordion-toggle .icon {
  margin-left: 24px;
}
.accordion-toggle--open .icon {
  transform: rotate(180deg);
}
.accordion__item {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  font-size: 18px;
  line-height: 22px;
  padding: 14px 40px;
  border-top: 1px solid #ccc;
}
.accordion__item .icon {
  display: none;
}
.accordion__item .button {
  min-width: 0;
  border: none;
  padding: 0 !important;
}
.accordion__item.clickable {
  display: flex;
  justify-content: space-between;
}
.accordion__item.clickable .icon {
  display: inline-block;
  fill: #4183d7;
  opacity: 0;
  transform: translate(-8px);
  transition: all 300ms ease-out;
}
.accordion__item.clickable:hover {
  background: rgba(65,131,215,0.05);
  box-shadow: inset 0 0 0 1px #6bb9f0;
  cursor: pointer;
}
.accordion__item.clickable:hover .icon {
  opacity: 1;
  transform: translate(0);
}
.credential-category-color {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 2px;
  vertical-align: middle;
  position: relative;
  top: -2px;
  margin-right: 16px;
}
.generic-list__container > * + * {
  margin-top: 64px;
}
.generic-list__body .generic-list__description {
  padding: 32px 20px;
}
.generic-list__footer {
  padding: 20px;
  border-top: 1px solid #ccc;
}
.generic-list__footer .button + .button {
  margin-left: 16px;
}
.generic-list--empty {
  text-align: center;
  padding: 48px 20px;
  min-height: 200px;
}
.generic-list--empty .icon {
  width: 48px;
  height: 48px;
  fill: #ccc;
  margin: 0 auto;
}
.generic-list--empty .icon + p {
  margin-top: 48px;
}
.generic-list--empty p {
  font-size: 22px;
}
.generic-list--empty p + .button {
  margin-top: 40px;
}
dl {
  white-space: normal;
}
dl > * {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
@media (min-width: 600px) {
  dl > * {
    flex-direction: row;
  }
}
dl > * + * {
  margin-top: 12px;
}
dl dt {
  width: 100%;
  font-weight: 700;
  margin-bottom: 4px;
  flex-shrink: 0;
}
@media (min-width: 600px) {
  dl dt {
    width: 180px;
    margin-bottom: 0;
  }
}
.cell-spacing {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 6px 0;
}
.cell-spacing > * + * {
  margin-left: 20px;
}
.cell-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 40%;
}
.icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background: transparent;
  fill: #201c23;
  pointer-events: none;
  flex-shrink: 0;
}
.icon svg {
  width: 100%;
  height: 100%;
}
.rotate-90 {
  transform: rotate(90deg);
}
.rotate-270 {
  transform: rotate(270deg);
}
.menu {
  position: relative;
  z-index: 97;
}
.menu .underlay {
  z-index: -1;
  background: transparent;
}
.menu--icon {
  display: block;
  padding: 12px;
}
.menu--dropdown {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  min-width: 180px;
  max-width: 240px;
  position: absolute;
  top: 12px;
  right: 12px;
  background: #fff;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
  padding-top: 8px;
  padding-bottom: 8px;
}
.menu--dropdown .dropdown__item {
  width: 100%;
  color: #201c23;
  font-size: 16px;
  font-weight: $medium;
  line-height: 36px;
  text-decoration: none;
}
.menu--dropdown .dropdown__item .icon {
  fill: #201c23;
}
.menu--dropdown .dropdown__item:hover {
  color: #4183d7;
  cursor: pointer;
}
.menu--dropdown .dropdown__item:hover .icon {
  fill: #4183d7;
}
.menu--dropdown .dropdown__item__children {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 36px;
  padding-right: 16px;
  padding-left: 16px;
}
.menu--dropdown .dropdown__item__children .icon {
  margin-right: 20px;
}
.menu--dropdown .dropdown__item__children .text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dimmer.visible.transition {
  display: flex !important;
}
.ui.modal {
  margin-top: 0px !important;
}
.ui.modal>.header:not(.ui) {
  font-family: 'Roboto Condensed', sans-serif;
  display: flex;
  justify-content: center;
}
.ui.modal>.content {
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
}
.ui.modal>.content>.modal__body {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.ui.modal>.content>.modal__footer {
  display: flex;
  justify-content: space-around;
  flex-flow: row wrap;
  width: 100%;
  margin-top: 20px;
}
.ui.modal>.content button {
  width: 280px;
  margin-bottom: 20px;
}
.notification-banner-container {
/* Positioning */
  position: sticky;
  top: 24px;
  z-index: 2000;
/* Styling */
  height: 0px;
}
@media (min-width: 900px) {
  .notification-banner-container {
    top: 40px;
  }
}
.notification-banner {
/* Styling */
  width: 95%;
  margin: 0 auto 0;
  display: flex;
  background: #626065;
  background-image: repeating-linear-gradient(45deg, #626065, #626065 15px, #5f5d62 15px, #5f5d62 30px);
  color: #fff;
  padding: 20px;
  box-shadow: 0 0px 6px 0 rgba(0,0,0,0.12), 0 6px 10px 0 rgba(0,0,0,0.16);
  border-radius: 4px;
  opacity: 0;
  visibility: hidden;
  transition: all ease-in-out 1s;
}
.notification-banner.notification-banner--visible {
  opacity: 1;
  visibility: visible;
}
.notification-banner.notification-banner--status-success {
  background: #2ecc71;
  background-image: repeating-linear-gradient(45deg, #2ecc71, #2ecc71 15px, #2dc66e 15px, #2dc66e 30px);
}
.notification-banner.notification-banner--status-error {
  background: #f96763;
  background-image: repeating-linear-gradient(45deg, #f96763, #f96763 15px, #f95d59 15px, #f95d59 30px);
}
.notification-banner .icon {
  width: 32px;
  height: 32px;
  color: #fff;
}
.notification-banner .icon svg {
  fill: currentColor;
}
.notification-strip__disabled {
  width: 100%;
  height: 56px;
  background-color: #f46678;
  color: #fff;
  display: flex;
  align-items: center;
}
.notification-strip__icon {
  margin-left: 24px;
  margin-right: 12px;
}
.notification-strip__icon svg {
  fill: #fff;
}
.notification-banner__message {
  flex: 1;
  line-height: 1.3;
  font-weight: 700;
  margin: 0 30px;
  padding-top: 8px;
  white-space: pre-wrap;
}
.notification-banner__dismiss {
  flex: 0;
  text-align: right;
  align-self: flex-start;
}
@media (max-width: 480px) {
  .notification-banner .icon:first-child {
    flex-basis: 0;
  }
  .notification-banner__message {
    margin: 0 10px;
  }
}
.paper {
  width: 100%;
  height: auto;
  background: #fff;
  border: 1px solid #ccc;
}
.paper__header {
  display: flex;
  flex-wrap: nowrap;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  margin-top: -1px;
  margin-bottom: -1px;
  box-shadow: -8px 0px 0px 0px #4183d7;
  margin-left: 8px;
}
.paper__header--back {
  display: flex;
  align-items: center;
  fill: #ccc;
  padding: 0px 12px;
  transition: all 150ms ease-in-out;
  border-right: 1px solid #ccc;
}
.paper__header--back:hover {
  fill: #201c23;
}
.paper__header--back svg {
  display: block;
  width: 32px;
  height: 32px;
}
.paper__header--title {
  font-size: 28px;
  padding: 12px 20px;
  margin-right: auto;
}
.paper__header--actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  padding: 0 8px;
}
.paper__footer {
  display: flex;
  flex-wrap: nowrap;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  margin-top: -1px;
  margin-bottom: -1px;
}
.paper__footer--forward,
.paper__footer--back {
  display: flex;
  align-items: center;
  fill: #ccc;
  padding: 16px;
  transition: all 150ms ease-in-out;
}
.paper__footer--forward:hover,
.paper__footer--back:hover {
  fill: #201c23;
}
.paper__footer--forward svg,
.paper__footer--back svg {
  display: block;
  width: 32px;
  height: 32px;
}
.paper-switch__parent {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}
.paper-switch__parent .paper-switch__toggle-group--slider {
  position: relative;
  width: 50px;
  height: 25px;
  margin-left: 24px;
}
.paper-switch__parent .paper-switch__input {
  display: none;
}
.paper-switch__parent .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.paper-switch__parent .slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 3px;
  bottom: 3px;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.paper-switch__parent .paper-switch__input.switchedOn + .slider {
  background-color: #2196f3;
}
.paper-switch__parent .paper-switch__input.switchedOn + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.paper-switch__parent .slider.round {
  border-radius: 24px;
}
.paper-switch__parent .slider.round:before {
  border-radius: 50%;
}
.paper__footer-forward {
  border-left: 1px solid #ccc;
}
.paper__footer-back {
  border-right: 1px solid #ccc;
}
.paper__footer--back-title {
  font-size: 28px;
  padding: 18px 20px;
  margin-right: auto;
}
.paper__footer--forward-title {
  font-size: 28px;
  padding: 18px 20px;
  margin-left: auto;
}
.paper__body {
  padding: 16px;
}
@media (min-width: 600px) {
  .paper__body {
    padding: 24px;
  }
}
@media (min-width: 900px) {
  .paper__body {
    padding: 32px;
  }
}
.expandable-row__container {
  margin: 10px 0;
  background-color: #fff;
}
.expandable-row {
  display: flex;
  align-items: center;
  min-height: 50px;
  padding: 10px 20px;
  border: 1px solid #ccc;
  position: relative;
  cursor: pointer;
  font-size: 18px;
}
.expandable-row .expandable-row__column {
  flex: 1 0;
}
.expandable-row .expandable-row__column:first-of-type {
  font-weight: bold;
}
.icon.anchored,
.icon.draggable {
  opacity: 0.5;
}
.icon.anchored {
  transform: scale(0.65);
}
.anchored-row-name,
.draggable-row-name {
  display: flex;
  align-items: center;
}
.anchored-row-name .icon,
.draggable-row-name .icon {
  margin: 0 10px 0 0;
}
.expandable-row__expanded-content {
  border: 1px solid #ccc;
  border-top: 0;
}
.expandable-row__expanded-content p {
  padding: 20px 20px 0;
}
.expandable-row__dropdown {
  cursor: pointer;
  min-width: 24px;
  margin-left: 20px;
}
.expandable-row__dropdown--expanded .icon {
  transform: rotate(180deg);
}
.expandable-row__title {
  font-weight: 700;
  line-height: 24px;
}
.expandable-row__title .icon {
  float: left;
  margin-right: 5px;
}
.expandable-row__switch {
  display: flex;
  align-items: center;
}
.expandable-row__switch span {
  margin-left: 14px;
  opacity: 0.7;
}
.expandable-row__switch .maximum-requestable span {
  margin-left: 0px;
}
.expandable-row__notification-column {
  display: flex;
  align-items: center;
}
.expandable-row__notification-column > * + * {
  margin-left: 20px;
}
.tag {
  display: inline-block;
  padding: 4px 8px;
  background: #aaa;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 4px;
}
.tag--you {
  background: #2ecc71;
}
.tag--invited {
  background: #ffd653;
}
.tag.input-label--optional {
  background: #f5f5f5;
  color: #aaa;
}
.tag--medium {
  font-size: 16px;
  padding: 8px 12px;
  text-align: center;
  min-width: 100px;
  border-radius: 2px;
  text-transform: capitalize;
}
.tag--primary {
  background: #4183d7;
}
.tag--category {
  color: #626065;
  font-size: 14px;
}
.react-hint {
  padding: 4px;
  position: absolute;
  z-index: 9999;
  cursor: default;
  animation: 0.5s fadeIn;
}
.react-hint__content {
  padding: 6px;
  border-radius: 5px;
  background: #000;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
}
.react-hint:after {
  content: '';
  width: 0;
  height: 0;
  margin: auto;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 5px solid transparent;
}
.react-hint--top:after {
  top: auto;
  border-bottom: none;
  border-top-color: #000;
}
.react-hint--left:after {
  left: auto;
  border-right: none;
  border-left-color: #000;
}
.react-hint--right:after {
  right: auto;
  border-left: none;
  border-right-color: #000;
}
.react-hint--bottom:after {
  bottom: auto;
  border-top: none;
  border-bottom-color: #000;
}
@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.unread-indicator {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  height: 10px;
  margin-left: 8px;
  width: 10px;
  border-radius: 50%;
  background-color: #c1c0c2;
}
.unread-indicator--active {
  background-color: #4183d7;
}
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 392px);
}
.spinner {
  margin: auto 0;
  width: 70px;
  text-align: center;
}
.spinner.large {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
}
.spinner.large div {
  height: 25px;
  width: 25px;
}
.spinner > div {
  width: 10px;
  height: 10px;
  margin-right: 4px;
  background-color: #333;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}
.spinner > div:last-child {
  margin-right: 0;
}
.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}
.reconcilation {
  display: flex;
  align-items: center;
  justify-content: center;
}
.reconcilation .loading {
  height: 40px;
}
@-moz-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@-o-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.info-card__list {
  margin: 20px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.info-card__list:after {
  content: "";
  flex: 0 0 32%;
}
.info-card {
  flex: 0 0 95%;
  margin: 0 20px 20px;
}
@media (min-width: 900px) {
  .info-card {
    flex: 0 0 48%;
    margin: 0 0 20px;
  }
}
@media (min-width: 1200px) {
  .info-card {
    flex: 0 0 32%;
    margin: 0 0 20px;
  }
}
.info-card__kabob-links {
  margin: 10px 20px;
}
.info-card__kabob-links a,
.info-card__kabob-links button {
  display: block;
}
.info-card__kabob-links:first-child {
  margin-bottom: 20px;
}
.info-card__header {
  padding: 24px 20px;
  background-color: #4183d7;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
}
.info-card__header.transportation-status--pending {
  background-color: #ccc;
}
.info-card__header.transportation-status--approved {
  background-color: #2ecc71;
}
.info-card__header.transportation-status--rejected {
  background-color: #e74c3c;
}
.info-card__header .icon {
  fill: #fff;
}
.info-card__header .menu--icon {
  padding: 0;
}
.info-card__header .info-card__header__date {
  display: flex;
  align-items: center;
}
.info-card__header .info-card__header__date .icon {
  margin-right: 10px;
}
.info-card dl {
  border: 1px solid #ccc;
}
.info-card dl div {
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  background-color: #fff;
  margin-top: 0;
}
@media (min-width: 900px) {
  .info-card dl div {
    align-items: center;
  }
}
.info-card dl div:nth-child(even) {
  background-color: #f4f7fa;
}
.info-card dl div dt,
.info-card dl div dd {
  color: #5a6879;
}
.info-card dl div dt {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  width: auto;
}
.info-card dl div dd {
  font-size: 18px;
}
.info-card dl div.info-card__stack {
  flex-direction: column;
  align-items: flex-start;
}
.info-card dl div.info-card__stack dt {
  margin-bottom: 10px;
}
.info-card dl div.info-card__stack dd div {
  background: transparent;
  padding: 0;
  margin: 0;
}
.info-card dl div.info-card__stack dd div:first-child {
  font-weight: bold;
}
.application__wrapper {
  display: flex;
  flex-direction: column;
}
@media (min-width: 1200px) {
  .application__wrapper {
    flex-direction: row;
  }
}
.application__wrapper section {
  flex: 1;
}
.application__wrapper section:after {
  content: "";
  display: table;
  clear: both;
}
.application__wrapper aside {
  margin-top: 10px;
  padding-left: 0px;
  flex: 0 0 320px;
}
@media (min-width: 1200px) {
  .application__wrapper aside {
    padding-left: 10px;
  }
}
.application__wrapper aside:after {
  content: "";
  display: table;
  clear: both;
}
.application__wrapper aside > div {
  background: #fff;
  border: 1px solid #ccc;
  padding: 20px;
}
.application__wrapper aside h1,
.application__wrapper aside h2 {
  text-transform: uppercase;
  font-size: 16px;
  color: #5a6879;
  margin: 0 0 10px;
}
.application__wrapper aside h2 {
  text-transform: none;
  opacity: 0.7;
  font-weight: 400;
  margin: 0;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}
.application__wrapper aside .generic-form__body {
  padding: 10px 0;
}
.application__wrapper aside .generic-form__body .input-scaffold {
  margin: 0 0 10px;
}
.application__wrapper aside .generic-form__body .input-scaffold.close-date {
  margin-top: 20px;
}
.application__wrapper aside .generic-form__body .input-scaffold .input-label {
  font-size: 16px;
}
.application__wrapper aside .generic-form__body .input-scaffold .SingleDatePicker {
  width: 100%;
}
.application__wrapper aside .generic-form__footer {
  padding: 20px 0;
}
.override-flexbox .input-scaffold {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
}
.override-flexbox .input-scaffold .input-label {
  margin: 0;
}
.application-sidebar__last-published {
  opacity: 0.5;
  font-size: 14px;
  margin: 10px 0 40px;
  text-align: center;
}
.media-application-sidebar__public-link {
  border-top: 1px solid #ccc;
  padding: 10px 0;
  word-break: break-all;
}
.media-application-sidebar__public-link .public-link__close-date {
  opacity: 0.5;
  font-size: 14px;
  margin-top: 5px;
}
.application-preview-button {
  display: block;
  width: 100%;
  font-size: 18px;
  padding: 12px 0;
  margin: -20px 0 0;
  color: #4183d7;
}
.application-preview-button:hover {
  color: #446cb3;
}
.draftjs-output ul,
.draftjs-output ol {
  margin: 20px 0 20px 40px;
}
.draftjs-output ul li:not(:last-child),
.draftjs-output ol li:not(:last-child) {
  margin-bottom: 8px;
}
.draftjs-output ul li {
  list-style-type: disc;
}
.draftjs-output ol li {
  list-style-type: decimal;
}
.toggle-group {
  background: #fff;
  border: 1px solid #ccc;
}
.toggle-group__parent,
.toggle-group__child {
  display: flex;
  align-items: flex-start;
  background: #fff;
  padding: 10px 20px;
  border-bottom: 1px solid #ccc;
}
.toggle-group__parent:last-child,
.toggle-group__child:last-child {
  border-bottom: none;
}
.toggle-group__parent .toggle-group__item--label {
  margin-left: 4px;
  font-size: 18px;
}
.toggle-group__child {
  border-left: 4px solid #ccc;
}
.toggle-group__item {
  flex: 0 0 auto;
}
.toggle-group__item--label {
  flex: 0 0 200px;
  margin-top: 5px;
}
.toggle-group__item--description {
  flex: 1 1 auto;
  margin: 0 20px;
  display: flex;
}
.toggle-group__item--description .icon {
  margin-right: 5px;
  opacity: 0.25;
}
.toggle-group__item--description p {
  margin-top: 2px;
}
.toggle-group__item--description.toggledOff {
  opacity: 0.5;
}
.toggle-group__item--toggle .toggle-scaffold.toggle--switch .toggle-icon {
  width: auto;
}
.toggle-group__item--toggle .toggle-scaffold .toggle-icon {
  overflow: inherit;
  height: 24px;
  padding: 0px;
}
.DraftEditor-editorContainer,
.DraftEditor-root,
.public-DraftEditor-content {
  height: inherit;
  text-align: initial;
}
.DraftEditor-root {
  position: relative;
}
.DraftEditor-editorContainer {
  background-color: rgba(255,255,255,0);
  border-left: 0.1px solid transparent;
  position: relative;
  z-index: 1;
}
.public-DraftEditor-block {
  position: relative;
}
.DraftEditor-alignLeft .public-DraftStyleDefault-block {
  text-align: left;
}
.DraftEditor-alignLeft .public-DraftEditorPlaceholder-root {
  left: 0;
  text-align: left;
}
.DraftEditor-alignCenter .public-DraftStyleDefault-block {
  text-align: center;
}
.DraftEditor-alignCenter .public-DraftEditorPlaceholder-root {
  margin: 0 auto;
  text-align: center;
  width: 100%;
}
.DraftEditor-alignRight .public-DraftStyleDefault-block {
  text-align: right;
}
.DraftEditor-alignRight .public-DraftEditorPlaceholder-root {
  right: 0;
  text-align: right;
}
.public-DraftEditorPlaceholder-root {
  color: #9197a3;
  position: absolute;
  z-index: 0;
}
.public-DraftEditorPlaceholder-hasFocus {
  color: #bdc1c9;
}
.DraftEditorPlaceholder-hidden {
  display: none;
}
.public-DraftStyleDefault-block {
  position: relative;
  white-space: pre-wrap;
}
.public-DraftStyleDefault-ltr {
  direction: ltr;
  text-align: left;
}
.public-DraftStyleDefault-rtl {
  direction: rtl;
  text-align: right;
}
.public-DraftStyleDefault-listLTR {
  direction: ltr;
}
.public-DraftStyleDefault-listRTL {
  direction: rtl;
}
.public-DraftStyleDefault-ol,
.public-DraftStyleDefault-ul {
  margin: 16px 0;
  padding: 0;
}
.public-DraftStyleDefault-depth0.public-DraftStyleDefault-listLTR {
  margin-left: 1.5em;
}
.public-DraftStyleDefault-depth0.public-DraftStyleDefault-listRTL {
  margin-right: 1.5em;
}
.public-DraftStyleDefault-depth1.public-DraftStyleDefault-listLTR {
  margin-left: 3em;
}
.public-DraftStyleDefault-depth1.public-DraftStyleDefault-listRTL {
  margin-right: 3em;
}
.public-DraftStyleDefault-depth2.public-DraftStyleDefault-listLTR {
  margin-left: 4.5em;
}
.public-DraftStyleDefault-depth2.public-DraftStyleDefault-listRTL {
  margin-right: 4.5em;
}
.public-DraftStyleDefault-depth3.public-DraftStyleDefault-listLTR {
  margin-left: 6em;
}
.public-DraftStyleDefault-depth3.public-DraftStyleDefault-listRTL {
  margin-right: 6em;
}
.public-DraftStyleDefault-depth4.public-DraftStyleDefault-listLTR {
  margin-left: 7.5em;
}
.public-DraftStyleDefault-depth4.public-DraftStyleDefault-listRTL {
  margin-right: 7.5em;
}
.public-DraftStyleDefault-unorderedListItem {
  list-style-type: square;
  position: relative;
}
.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth0 {
  list-style-type: disc;
}
.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth1 {
  list-style-type: circle;
}
.public-DraftStyleDefault-orderedListItem {
  list-style-type: none;
  position: relative;
}
.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listLTR:before {
  left: -36px;
  position: absolute;
  text-align: right;
  width: 30px;
}
.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listRTL:before {
  position: absolute;
  right: -36px;
  text-align: left;
  width: 30px;
}
.public-DraftStyleDefault-orderedListItem:before {
  content: counter(ol0) ". ";
  counter-increment: ol0;
}
.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth1:before {
  content: counter(ol1) ". ";
  counter-increment: ol1;
}
.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth2:before {
  content: counter(ol2) ". ";
  counter-increment: ol2;
}
.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth3:before {
  content: counter(ol3) ". ";
  counter-increment: ol3;
}
.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth4:before {
  content: counter(ol4) ". ";
  counter-increment: ol4;
}
.public-DraftStyleDefault-depth0.public-DraftStyleDefault-reset {
  counter-reset: ol0;
}
.public-DraftStyleDefault-depth1.public-DraftStyleDefault-reset {
  counter-reset: ol1;
}
.public-DraftStyleDefault-depth2.public-DraftStyleDefault-reset {
  counter-reset: ol2;
}
.public-DraftStyleDefault-depth3.public-DraftStyleDefault-reset {
  counter-reset: ol3;
}
.public-DraftStyleDefault-depth4.public-DraftStyleDefault-reset {
  counter-reset: ol4;
}
.DraftJSEditor-root {
  background: #fff;
  border: 1px solid #ddd;
  font-family: inherit;
  font-size: 14px;
  padding: 15px;
}
.DraftJSEditor-editor {
  border-top: 1px solid #ddd;
  cursor: text;
  font-size: 16px;
  margin-top: 10px;
}
.DraftJSEditor-editor .public-DraftEditorPlaceholder-root,
.DraftJSEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px;
}
.DraftJSEditor-editor .public-DraftEditor-content {
  min-height: 100px;
}
.DraftJSEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}
.DraftJSEditor-controls {
  font-family: inherit, sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  user-select: none;
}
.DraftJSEditor-styleButton {
  color: #999;
  fill: #999;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
}
.DraftJSEditor-activeButton {
  color: #5890ff;
  fill: #5890ff;
}
.drop-n-crop {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.drop-n-crop .cropper-scaffold__controls {
  width: 100%;
  text-align: center;
  margin-top: 16px;
}
.dropzone {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-align: center;
  background: #fff;
  border: 2px dashed #ddd;
  padding: 60px 30px;
  max-width: 350px;
  transition: all 300ms ease-in-out;
}
.dropzone.dropzone--active {
  background: #f2f2f2;
  border-color: #b1b1b1;
}
.dropzone.dropzone--reject {
  background: rgba(255,0,0,0.4);
  border-color: #f00;
}
.dropzone-instructions {
  transition: all 300ms ease-in-out;
}
.dropzone.dropzone--active .dropzone-instructions {
  transform: scale(1.1);
}
.dropzone-instructions--main {
  color: #322d35;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 8px;
}
.dropzone-instructions--sub {
  color: #201c23;
  font-style: italic;
  margin-top: 4px;
}
.dropzone-validation {
  color: #ff3d00;
  font-size: 16px;
  font-weight: bold;
  margin-top: 32px;
}
.cropper-container {
  font-size: 0;
  line-height: 0;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  direction: ltr;
  -ms-touch-action: none;
  touch-action: none;
}
.cropper-container img {
/* Avoid margin top issue (Occur only when margin-top <= -height) */
  display: block;
  min-width: 0 !important;
  max-width: none !important;
  min-height: 0 !important;
  max-height: none !important;
  width: 100%;
  height: 100%;
  image-orientation: 0deg;
}
.cropper-wrap-box,
.cropper-canvas,
.cropper-drag-box,
.cropper-crop-box,
.cropper-modal {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.cropper-wrap-box {
  overflow: hidden;
}
.cropper-drag-box {
  opacity: 0;
  background-color: #fff;
}
.cropper-modal {
  opacity: 0.5;
  background-color: #000;
}
.cropper-view-box {
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
  outline: 1px solid #39f;
  outline-color: rgba(51,153,255,0.75);
}
.cropper-dashed {
  position: absolute;
  display: block;
  opacity: 0.5;
  border: 0 dashed #eee;
}
.cropper-dashed.dashed-h {
  top: 33.33333%;
  left: 0;
  width: 100%;
  height: 33.33333%;
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.cropper-dashed.dashed-v {
  top: 0;
  left: 33.33333%;
  width: 33.33333%;
  height: 100%;
  border-right-width: 1px;
  border-left-width: 1px;
}
.cropper-center {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 0;
  height: 0;
  opacity: 0.75;
}
.cropper-center:before,
.cropper-center:after {
  position: absolute;
  display: block;
  content: ' ';
  background-color: #eee;
}
.cropper-center:before {
  top: 0;
  left: -3px;
  width: 7px;
  height: 1px;
}
.cropper-center:after {
  top: -3px;
  left: 0;
  width: 1px;
  height: 7px;
}
.cropper-face,
.cropper-line,
.cropper-point {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  opacity: 0.1;
}
.cropper-face {
  top: 0;
  left: 0;
  background-color: #fff;
}
.cropper-line {
  background-color: #39f;
}
.cropper-line.line-e {
  top: 0;
  right: -3px;
  width: 5px;
  cursor: e-resize;
}
.cropper-line.line-n {
  top: -3px;
  left: 0;
  height: 5px;
  cursor: n-resize;
}
.cropper-line.line-w {
  top: 0;
  left: -3px;
  width: 5px;
  cursor: w-resize;
}
.cropper-line.line-s {
  bottom: -3px;
  left: 0;
  height: 5px;
  cursor: s-resize;
}
.cropper-point {
  width: 5px;
  height: 5px;
  opacity: 0.75;
  background-color: #39f;
}
.cropper-point.point-e {
  top: 50%;
  right: -3px;
  margin-top: -3px;
  cursor: e-resize;
}
.cropper-point.point-n {
  top: -3px;
  left: 50%;
  margin-left: -3px;
  cursor: n-resize;
}
.cropper-point.point-w {
  top: 50%;
  left: -3px;
  margin-top: -3px;
  cursor: w-resize;
}
.cropper-point.point-s {
  bottom: -3px;
  left: 50%;
  margin-left: -3px;
  cursor: s-resize;
}
.cropper-point.point-ne {
  top: -3px;
  right: -3px;
  cursor: ne-resize;
}
.cropper-point.point-nw {
  top: -3px;
  left: -3px;
  cursor: nw-resize;
}
.cropper-point.point-sw {
  bottom: -3px;
  left: -3px;
  cursor: sw-resize;
}
.cropper-point.point-se {
  right: -3px;
  bottom: -3px;
  width: 20px;
  height: 20px;
  cursor: se-resize;
  opacity: 1;
}
@media (min-width: 768px) {
  .cropper-point.point-se {
    width: 15px;
    height: 15px;
  }
}
@media (min-width: 992px) {
  .cropper-point.point-se {
    width: 10px;
    height: 10px;
  }
}
@media (min-width: 1200px) {
  .cropper-point.point-se {
    width: 5px;
    height: 5px;
    opacity: 0.75;
  }
}
.cropper-point.point-se:before {
  position: absolute;
  right: -50%;
  bottom: -50%;
  display: block;
  width: 200%;
  height: 200%;
  content: ' ';
  opacity: 0;
  background-color: #39f;
}
.cropper-invisible {
  opacity: 0;
}
.cropper-bg {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC");
}
.cropper-hide {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
}
.cropper-hidden {
  display: none !important;
}
.cropper-move {
  cursor: move;
}
.cropper-crop {
  cursor: crosshair;
}
.cropper-disabled .cropper-drag-box,
.cropper-disabled .cropper-face,
.cropper-disabled .cropper-line,
.cropper-disabled .cropper-point {
  cursor: not-allowed;
}
#root,
#root > div,
.main-layout,
.onsite-layout {
  min-height: 100vh;
  overflow-x: clip;
}
.main-layout {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.main-layout__body {
  position: relative;
  width: 100%;
  overflow-x: clip;
}
@media (min-width: 900px) {
  .main-layout__body {
    width: calc(100% - 250px);
  }
}
.main-layout__children {
  padding: 8px;
}
@media (min-width: 600px) {
  .main-layout__children {
    padding: 16px;
  }
}
@media (min-width: 900px) {
  .main-layout__children {
    padding: 24px;
  }
}
.underlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background: rgba(32,28,35,0.5);
  z-index: 1;
}
.onsite-layout {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
}
.onsite-layout__body {
  position: relative;
  width: 600px;
  overflow-x: hidden;
}
@media (min-width: 600px) {
  .onsite-layout__body {
    margin: 20px;
    -webkit-box-shadow: 0px 0px 5px 5px rgba(211,211,211,0.7);
    box-shadow: 0px 0px 5px 5px rgba(211,211,211,0.7);
  }
}
.onsite-layout__children {
  padding: 8px;
}
@media (min-width: 600px) {
  .onsite-layout__children {
    padding: 16px;
  }
}
@media (min-width: 900px) {
  .onsite-layout__children {
    padding: 24px;
  }
}
.sidebar {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 250px;
  height: auto;
  min-height: 100vh;
  background: #322d35;
  color: #fff;
  flex-shrink: 0;
  transform: translateX(-250px);
  transition: transform 200ms ease-out;
  z-index: 99;
}
.mobile-nav--open .sidebar {
  height: 100%;
  overflow-y: auto;
  transform: translateX(0);
}
@media (min-width: 900px) {
  .sidebar {
    position: relative;
    z-index: auto;
    transform: none;
  }
}
.sidebar__logo {
  display: flex;
  align-items: center;
  width: 100%;
  height: 80px;
  background: #201c23;
  padding-left: 40px;
  padding-right: 48px;
}
.sidebar__logo > svg {
  width: 162px;
  height: 50px;
  fill: #446cb3;
}
.sidebar__back {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  font-weight: 400;
  text-decoration: none;
  color: rgba(255,255,255,0.6);
  height: 42px;
  padding: 9px 12px;
  background: #201c23;
  border-left: 4px solid transparent;
}
.sidebar__back.sidebar__back--empty {
  background: transparent;
}
.sidebar__back .icon {
  fill: rgba(255,255,255,0.6);
}
.sidebar__back .icon + .name {
  margin-left: 24px;
}
.sidebar__back:hover {
  color: #fff;
}
.sidebar__back:hover .icon {
  fill: #fff;
}
.sidebar__nav__wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  width: 100%;
  background: #28242a;
}
.sidebar__navigation {
  width: 100%;
}
.sidebar__navigation a {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  font-weight: 400;
  text-decoration: none;
  color: rgba(255,255,255,0.6);
  padding: 9px 10px;
  border-left: 4px solid transparent;
}
.sidebar__navigation a .icon {
  fill: rgba(255,255,255,0.2);
}
.sidebar__navigation a .icon + .name {
  margin-left: 26px;
}
.sidebar__navigation a:hover {
  color: #fff;
}
.sidebar__navigation a:hover .icon {
  fill: rgba(255,255,255,0.8);
}
.sidebar__navigation a.active {
  color: #fff;
  background: #3e3942;
  border-left: 4px solid #4183d7;
  font-weight: 700;
}
.sidebar__navigation a.active .icon {
  fill: #4183d7;
}
.sidebar__navigation hr {
  background: #3e3942;
  margin: 0;
}
.sidebar__navigation.sidebar__navigation--condensed {
  width: 52px;
  background: #28242a;
}
.sidebar__navigation.sidebar__navigation--condensed a {
  border-left: 4px solid transparent;
}
.sidebar__navigation.sidebar__navigation--condensed a.active {
  background: #4183d7;
}
.sidebar__navigation.sidebar__navigation--condensed a.active .icon {
  fill: #fff;
}
.sidebar__subnav {
  width: calc(100% - 52px);
  background: #322d35;
}
.sidebar__subnav h4 {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  font-weight: 400;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  padding: 9px 16px;
}
.sidebar__subnav a {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  font-weight: 400;
  text-decoration: none;
  line-height: 24px;
  color: rgba(255,255,255,0.6);
  padding: 9px 16px;
}
.sidebar__subnav a:hover {
  color: #fff;
}
.sidebar__subnav a.active {
  color: #fff;
  background: #4183d7;
  font-weight: 700;
}
.sidebar__subnav a.sidebar__subnav-subitem {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  font-weight: 400;
  text-decoration: none;
  color: rgba(255,255,255,0.6);
  line-height: 1;
  padding: 9px 12px;
  background: #3e3942;
  border-left: 4px solid transparent;
}
.sidebar__subnav a.sidebar__subnav-subitem:hover {
  color: #fff;
}
.sidebar__subnav a.sidebar__subnav-subitem.active {
  color: #fff;
  border-left: 4px solid #4183d7;
  font-weight: 700;
}
a.sidebar__subsubnav {
  background: #3e3942;
  position: relative;
}
a.sidebar__subsubnav.active {
  color: #fff;
  opacity: 1;
  font-weight: 400;
  background: #3e3942;
}
a.sidebar__subsubnav.active:before {
  content: "";
  display: inline-block;
  background: #4183d7;
  width: 4px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
}
.topbar {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 64px;
  background: #fff;
  color: #201c23;
  padding: 8px 12px;
}
@media (min-width: 900px) {
  .topbar {
    height: 80px;
    padding: 16px 40px;
  }
}
.topbar__breadcrumbs {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  font-size: 24px;
  line-height: 1;
}
@media (min-width: 900px) {
  .topbar__breadcrumbs {
    font-size: 32px;
  }
}
.topbar__breadcrumbs > * + * {
  margin-left: 12px;
  position: relative;
}
.topbar__breadcrumbs > button {
  padding: 8px;
}
.topbar__breadcrumbs--divider {
  color: rgba(32,28,35,0.2);
}
.topbar__account {
  position: relative;
  display: block;
  margin-left: 20px;
}
.topbar__account--image {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background: #ccc;
  color: #fff;
  font-size: 16px;
  border-radius: 100%;
  overflow: hidden;
}
.topbar__account--image img {
  display: block;
}
.topbar__account--dropdown {
  display: flex;
  position: absolute;
  z-index: 99;
  top: 0;
  right: 0;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  width: 220px;
  background: #fff;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
}
.topbar__account--dropdown .topbar__account--account {
  margin: 16px 16px 0;
  padding-bottom: 16px;
  border-bottom: 1px solid #ccc;
}
.topbar__account--dropdown .topbar__account--welcome {
  font-size: 12px;
  margin-bottom: 8px;
}
.topbar__account--dropdown .topbar__account--name {
  font-size: 16px;
  margin-bottom: 6px;
}
.topbar__account--dropdown .topbar__account--email {
  color: rgba(32,28,35,0.5);
  font-size: 12px;
}
.topbar__account--dropdown .topbar__account--nav {
  margin-top: 8px;
  margin-bottom: 8px;
}
.topbar__account--dropdown .topbar__account--nav > a,
.topbar__account--dropdown .topbar__account--nav > button {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 36px;
  padding-right: 16px;
  padding-left: 16px;
  color: #201c23;
  font-size: 16px;
  font-weight: $medium;
  line-height: 36px;
  text-decoration: none;
  transition: all 100ms ease-out;
}
.topbar__account--dropdown .topbar__account--nav > a .icon,
.topbar__account--dropdown .topbar__account--nav > button .icon {
  fill: #201c23;
  margin-right: 20px;
  transition: all 100ms ease-out;
}
.topbar__account--dropdown .topbar__account--nav > a:hover,
.topbar__account--dropdown .topbar__account--nav > button:hover {
  cursor: pointer;
  color: #4183d7;
}
.topbar__account--dropdown .topbar__account--nav > a:hover .icon,
.topbar__account--dropdown .topbar__account--nav > button:hover .icon {
  fill: #4183d7;
}
.topbar__account--dropdown .topbar__account--nav > a.active,
.topbar__account--dropdown .topbar__account--nav > button.active {
  color: #4183d7;
}
.topbar__account--dropdown .topbar__account--nav > a.active .icon,
.topbar__account--dropdown .topbar__account--nav > button.active .icon {
  fill: #4183d7;
}
.topbar__divider {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
}
.user-info {
  padding: 20px;
}
.user-info > * + * {
  margin-top: 24px;
}
.user-info__back {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.user-info__profile {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
@media (min-width: 600px) {
  .user-info__profile {
    flex-direction: row;
  }
}
.user-info__profile__image {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  width: 132px;
  height: 132px;
  border-radius: 100%;
  background: #ccc;
  color: #fff;
  font-size: 60px;
  flex-shrink: 0;
}
.user-info__profile__image + .user-info__profile__description {
  margin-top: 32px;
}
@media (min-width: 600px) {
  .user-info__profile__image + .user-info__profile__description {
    margin-top: 0;
    margin-left: 48px;
  }
}
.user-info__profile__description {
  text-align: center;
}
@media (min-width: 600px) {
  .user-info__profile__description {
    text-align: left;
  }
}
.user-info__profile__description__email {
  color: rgba(32,28,35,0.7);
  margin-top: 16px;
}
.user-info__profile__description__status {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.user-info__profile__description__status > * + * {
  margin-left: 12px;
}
.user-info__profile__description__status .button {
  font-size: 16px;
}
.contact-info {
  padding: 20px;
}
.contact-info > * + * {
  margin-top: 24px;
}
.contact-info__back {
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.contact-info__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.contact-info__item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
@media (min-width: 600px) {
  .contact-info__item {
    flex-direction: row;
  }
}
.contact-info__item dt {
  width: 100%;
  font-weight: 700;
  margin-bottom: 4px;
  flex-shrink: 0;
}
@media (min-width: 600px) {
  .contact-info__item dt {
    width: 180px;
    margin-bottom: 0;
  }
}
.pass-fulfillment-field {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
}
.pass-fulfillment-field + .pass-fulfillment-field {
  border-top: 1px solid #ccc;
}
.pass-fulfillment-field__periods {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  max-width: 700px;
}
.pass-fulfillment-field__periods .button {
  flex-shrink: 0;
  margin-left: 18px;
}
.pass-fulfillment-field__separate-days__main,
.pass-fulfillment-field__whole-period__main {
  min-width: 240px;
  max-width: 240px;
}
.pass-fulfillment-field__separate-days__main .button,
.pass-fulfillment-field__whole-period__main .button {
  padding-left: 0;
  padding-right: 0;
  min-width: 0;
  font-size: 14px;
}
.pass-fulfillment-field__separate-days__dates {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
.pass-fulfillment-field__separate-days__dates > * {
  width: 180px;
  margin-left: 8px;
}
.pass-fulfillment-field__whole-period__dates {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.pass-fulfillment-field__whole-period__dates .toggle-scaffold {
  flex-shrink: 0;
}
.pass-fulfillment-field__whole-period__dates .input-scaffold {
  width: 180px;
  margin-left: 8px;
}
.credential-request-instructions {
  display: flex;
  align-items: center;
  min-height: 80px;
  border-bottom: 1px solid #ccc;
}
.credential-request-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  margin-left: -20px;
  margin-right: -20px;
}
.credential-request-category-list {
  width: 220px;
  margin: 20px;
}
.credential-request-category-list > * + * {
  margin-top: 12px;
}
.credential-request-category-list__item {
  display: flex;
  align-items: center;
}
.credential-request-category-list__item .icon {
  width: 18px;
  height: 18px;
  margin-right: 6px;
}
.credential-request-period {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
}
.credential-request-period__name {
  width: 240px;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 20px;
}
.credential-request-period__name h4 {
  margin-bottom: 18px;
}
.credential-request__apply-to-all-field {
  display: flex;
}
.credential-request__apply-to-all-field button {
  padding: 0;
  min-width: 0;
  margin-left: 12px;
  font-size: 14px;
  font-weight: 700;
}
.credential-request-period__dates,
.credential-request-period__fields {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  border-bottom: 1px solid #ccc;
  padding: 20px 20px 20px;
  margin-left: -10px;
}
.credential-request-period__dates > *,
.credential-request-period__fields > * {
  margin-left: 10px;
  margin-right: 20px;
}
.credential-request-period__dates .input-label,
.credential-request-period__fields .input-label {
  margin-bottom: 12px;
}
.credential-request-period__dates .input-label--label h4,
.credential-request-period__fields .input-label--label h4 {
  font-weight: 400;
}
.credential-request-list__buttons {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  margin-bottom: 10px;
}
.credential-request-list__buttons .button {
  margin: 10px 10px 0;
}
.credential-request-list__buttons .button .icon {
  fill: #fff;
}
.credential-request-list__buttons .button--outline .icon {
  fill: #4183d7;
}
@media (min-width: 600px) {
  .credential-request-list__buttons {
    margin: 20px 0;
    flex-direction: row;
  }
  .credential-request-list__buttons .button {
    margin: 0 20px 0 0;
  }
  .credential-request-list__buttons .button:last-child {
    margin-right: 0;
  }
}
@media (min-width: 900px) {
  .credential-request-list__buttons {
    margin-top: 0;
  }
}
.credential-request__date {
  display: flex;
  align-items: center;
}
.credential-request__date .icon {
  fill: #ccc;
  margin-right: 6px;
}
.department-settings > * + * {
  margin-top: 32px;
}
.department-form__assignment-workflow h4 {
  margin-bottom: 12px;
}
.department-form__assignment-workflow p {
  font-size: 18px;
  line-height: 1.4;
}
.assignment-email {
  display: flex;
  flex-direction: column;
}
.assignment-email__row {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.assignment-email__row__button {
  display: flex;
  justify-content: flex-end;
}
.assignment-email__column {
  display: flex;
  flex-direction: column;
}
.group-credential-email-history {
  margin-top: 10px;
  margin-left: 20px;
}
.group-credential-email-history__time-sent {
  margin-left: 20px;
  opacity: 0.7;
  line-height: 1.4;
}
.department-list-column-header {
  color: #201c23;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  margin: 0px;
}
.not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}
.not-found h1 {
  margin-bottom: 16px;
}
.catering-table-wrapper {
  overflow-x: auto;
}
.catering-period-summary table {
  width: 100%;
}
.catering-period-summary thead {
  background-color: #201c23;
  color: #fff;
}
.catering-period-summary thead th {
  padding: 16px 0;
}
.catering-period-summary tbody tr td {
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  padding-top: 20px;
  padding-bottom: 20px;
  white-space: nowrap;
  vertical-align: middle;
}
.catering-period-summary tbody tr td:last-child {
  border-right: none;
}
.meals-needed {
  display: flex;
  justify-content: center;
  padding: 0 30px;
}
.meals-needed > * + * {
  margin-left: 20px;
}
.meals-needed-field {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.meals-needed-field input {
  width: 80px;
}
.meals-needed-field__label {
  font-size: 14px;
  padding-bottom: 6px;
}
.meals-claimed__label {
  font-size: 14px;
  padding-top: 6px;
}
.meals-needed-field__subtext,
.quantity-field-max {
  display: inline-block;
  width: 100%;
  text-align: center;
  padding-top: 5px;
  font-size: 14px;
  color: #626065;
}
.meals-needed-field__subtext {
  color: #201c23;
}
.catering-table-date {
  padding: 0 40px;
  text-align: center;
}
.catering-table-no-meal,
.catering-table-no-meal-large {
  padding: 0 30px;
  text-align: center;
  opacity: 0.5;
}
.catering-table-no-meal {
  min-width: 141px;
}
.catering-table-no-meal-large {
  min-width: 341px;
}
.catering-summary {
  padding: 30px 20px;
}
.catering-summary p {
  padding-bottom: 15px;
}
.catering-summary hr {
  margin: 20px auto;
}
.catering-summary__indicators {
  margin-bottom: 5px;
}
.catering-summary__indicator {
  display: flex;
  align-items: center;
}
.catering-summary__indicator > span {
  margin-left: 10px;
}
.catering-summary__quantity {
  display: flex;
  align-items: center;
}
.catering-summary__quantity > span {
  padding-right: 10px;
}
.hidden-action-box {
  position: relative;
}
.add-contact-form,
.add-guest-form {
  background: #fff;
  position: absolute;
  left: -180px;
  z-index: 1;
  width: 250px;
  box-shadow: 0 2px 4px 0px rgba(0,0,0,0.16);
  border-radius: 2px;
  border: 1px solid #ccc;
}
.add-contact-form .input-label,
.add-guest-form .input-label {
  font-size: 15px;
  font-weight: 700;
  color: #626065;
}
.add-contact-form .generic-form__footer,
.add-guest-form .generic-form__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.add-contact-form:before,
.add-guest-form:before,
.add-contact-form:after,
.add-guest-form:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #bcbcbc;
  position: absolute;
  top: -10px;
  right: 20px;
}
.add-contact-form:after,
.add-guest-form:after {
  border-bottom-color: #fff;
  top: -9px;
}
.credential-assignment-form .cred-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.credential-assignment-form .cred-row:not(:last-child) {
  margin-bottom: 10px;
}
.credential-assignment-form .cred-row input[type="number"] {
  margin-left: 10px;
  width: 80px;
  padding: 4px;
}
.staff-details {
  position: relative;
  margin-left: 20px;
  background: #fff;
  border: 1px solid #ccc;
  width: 325px;
  flex: 1 0 325px;
}
.staff-details .profile-pic {
  background: #ccc;
  border-radius: 100%;
  color: #fff;
  width: 48px;
  height: 48px;
  text-align: center;
  margin-right: 20px;
}
.staff-details .profile-pic .icon {
  width: 40px;
  height: 40px;
  position: relative;
  top: 2px;
  left: 4px;
}
.staff-details .profile-pic svg {
  fill: currentColor;
}
.staff-details h1 {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 10px;
}
.staff-details h1 svg {
  width: 16px;
  height: 16px;
  margin-left: 10px;
}
.staff-details .contact-info {
  padding: 0;
}
.staff-details .contact-info div {
  margin: 0;
  padding: 0;
}
.staff-details .contact-info input {
  border: none;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
}
.staff-details .contact-info input:focus::placeholder {
  color: transparent;
}
.staff-details .expandable-row__expanded-content {
  padding: 10px;
}
.staff-details .add-contact-form form button {
  width: 100%;
  margin-top: 10px;
}
.staff-details .input-validation {
  color: #e74c3c;
}
.staff-details__relationship select {
  background: transparent;
  font-size: 16px;
  padding-left: 1px;
  line-height: 1;
  border: 0;
  border-radius: 0;
  -webkit-appearance: none;
  font-weight: 500;
  color: #201c23;
  width: 100%;
}
.staff-details__relationship-no-value select {
  color: rgba(32,28,35,0.5);
}
.staff-details__pending-credentials,
.staff-details__approved-credentials {
  padding: 0px 20px 20px;
}
.staff-details__pending-credentials h2,
.staff-details__approved-credentials h2 {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 10px;
}
.staff-details__pending-credentials h2 .icon,
.staff-details__approved-credentials h2 .icon {
  display: none;
}
.staff-details__pending-credentials .tag,
.staff-details__approved-credentials .tag {
  position: relative;
  padding: 8px 10px 8px 0;
  margin: 0 10px 10px 0;
}
.staff-details__pending-credentials .tag .credential-count,
.staff-details__approved-credentials .tag .credential-count {
  background: rgba(0,0,0,0.1) !important;
  padding: 7px 10px;
  margin-right: 10px;
  border-radius: 4px 0 0 4px;
}
.staff-details__pending-credentials .tag button,
.staff-details__approved-credentials .tag button {
  position: absolute;
  color: #fff;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0.7);
  width: 100%;
  text-align: right;
  border-radius: 4px;
}
.staff-details__pending-credentials .tag button svg,
.staff-details__approved-credentials .tag button svg {
  fill: #fff;
  width: 20px;
  margin: 2px 5px 0 0;
}
.staff-details-header {
  display: flex;
  padding: 20px 20px 10px;
  border-bottom: 1px solid #ccc;
}
.staff-details-header form {
  width: 180px;
}
.staff-details-header form .input-scaffold {
  margin-bottom: 10px;
}
.staff-details-header form .button {
  padding: 8px;
  min-width: 80px;
  margin-bottom: 20px;
}
.contact-details-close {
  position: absolute;
  top: 10px;
  right: 10px;
}
.contact-details-close svg {
  fill: #b0afb3;
}
.contact-details-close svg:hover {
  fill: #201c23;
}
.assign-creds-dropdown {
  display: flex;
  align-items: center;
  font-size: 16px;
}
.assign-creds-dropdown .icon {
  width: 20px;
  margin-right: 10px;
}
.guest-summary {
  margin-bottom: 20px;
}
.guest-row {
  font-size: 18px;
  color: #626065;
}
.guest-row:first-of-type {
  margin-bottom: 16px;
}
.guest-row .guest-summary-approved {
  color: #2ecc71;
}
.guest-row .guest-summary-unassigned {
  color: #4183d7;
}
.guest-row .icon {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  width: 32px;
  height: 32px;
}
.guest-row .icon svg {
  fill: #5a6879;
}
.guest__name-fields {
  display: flex;
}
.guest__name-fields input {
  border: none;
  background: transparent;
  font-size: 18px;
  color: #201c23;
  font-family: inherit;
  margin-right: 10px;
  font-weight: bold;
}
.credential-tags .tag {
  margin: 4px;
}
.credential-tags .guest--awaiting-approval {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
.contact-list__buttons {
  margin-bottom: 20px;
}
.contact-list__buttons .button {
  border: none;
  background: #5a6879;
  color: #fff;
  padding: 10px 16px;
  min-width: 120px;
  font-size: 16px;
}
.contact-list__buttons .button:hover {
  background: #5a6879;
  color: #fff;
  cursor: default;
}
.contact-list__buttons .button:first-child {
  border-radius: 4px 0 0 4px;
}
.contact-list__buttons .button:last-child {
  border-radius: 0 4px 4px 0;
}
.contact-list__buttons .button .icon svg {
  fill: currentColor;
}
.contact-list__buttons .button.button--outline {
  border: none;
  background: #ccc;
  min-width: 120px;
  color: #5a6879;
}
.contact-list__buttons .button.button--outline:hover {
  background: #485361;
  color: #fff;
  cursor: pointer;
}
.contact-list__buttons .button.button--outline .icon svg {
  fill: currentColor;
}
.approved-color,
.status-approved {
  color: #2ecc71;
}
.declined-color,
.status-rejected {
  color: #e74c3c;
}
.pending-color,
.status-pending {
  color: #dea74b;
}
.primary-contact-pill {
  background: #4183d7;
  font-size: 12px;
  text-transform: uppercase;
  color: #fff;
  padding: 2px 4px;
  border-radius: 2px;
  margin-left: 10px;
}
.group-contact-clipboard {
  margin-left: 10px;
}
.contact-details-section {
  display: flex;
  border-bottom: 1px solid #ccc;
}
.contact-details-section.guest-list {
  border-bottom: none;
}
.contact-details-section.guest-list .contact-credentials__display {
  margin-top: -1px;
}
.contact-details-section__header {
  border-left: 4px solid #ccc;
  border-right: 1px solid #ccc;
  flex: 0 0 250px;
  display: flex;
  padding: 15px 10px;
  font-size: 18px;
}
.contact-details-section__header .icon {
  position: relative;
  top: -4px;
  margin-right: 5px;
}
.contact-details-section__subtext {
  flex-direction: column;
}
.contact-details-section__subtext > div:first-of-type {
  display: flex;
  margin-bottom: 10px;
}
.contact-details-section__subtext .input-scaffold .input-label {
  font-size: 16px;
}
.contact-details-section__subtext .input-scaffold select {
  font-size: 16px;
}
.contact-details-section__subtext p {
  opacity: 0.7;
  line-height: 1.5;
  font-size: 16px;
}
.contact-details-section__content {
  width: 100%;
}
.contact-information-details {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  flex-wrap: wrap;
}
.contact-information-details__item {
  flex: 0 0 200px;
  margin-right: 20px;
}
.contact-information-details__primary .input-scaffold .input .toggle-scaffold .toggle {
  margin: 0;
  display: flex;
  align-items: center;
}
.contact-information-details__primary .input-scaffold .input .toggle-scaffold .toggle div .toggle-icon {
  padding: 0;
  width: 24px;
  height: 24px;
  margin-right: 5px;
}
.contact-information-details__primary .input-scaffold .input .toggle-scaffold .toggle-label {
  font-size: 16px;
  padding: 0;
}
.contact-information-details__email input,
.contact-information-details__phone input,
.contact-information-details__other-role input,
.contact-information-details__relationship input {
  font-size: 16px !important;
  border: none !important;
  padding: 0 !important;
}
.contact-details-section__footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  padding: 10px 20px;
}
.contact-credentials__item {
  padding: 10px 10px;
}
.contact-credentials__select select {
  display: inline-block;
  width: auto;
  color: inherit;
  font-family: inherit;
  font-size: 16px;
  line-height: 24px;
  border: 2px solid #ccc;
  border-radius: 2px;
  padding: 8px 12px;
  appearance: none;
  background: #fff;
  background-repeat: no-repeat;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAABYUlEQVRoge2YMU7EMBBF3yIuhFJyARo6aKHOCTYFHQU5AUegouMaEZehQ1pBgSMi5Dgz9hizYl4VxfbkP8lx7Oy6ruMYmKYpev/kl3OY4wKtcYHWuEBrXKA1LtAaF2hNjsCZeYqC2lqBHngFBu2DBAyhdq8ZpBHogcdw/YCtxBBqEp4hlpAKLMPPWEksw8+IJSQCO+Bipa1UIhZ+5lxSQCLwAVwDLyvtuRKp8E/AraSIdAq9A1ekJe6FtQh9U+FvgIOkkOYl3pK4A0ZBnTH0jaEKD/pldEtiT1piDH1iqMND3ocsV8I8PORvJbQSVcIDnOYMCswSz8BlpH2/cr2kKDyUCYBO4ifF4cFmN7o1nWKYhAe77bRGwiw82J4HJBKm4cH+QJOSMA8PdU5kMYkq4aF8FVpjuTq9USk81BOAb4kDlcJDXQH4kqjKv/wr8adwgda4QGtcoDUu0BoXaM3RC3wCkn5bVLDSEjUAAAAASUVORK5CYII=");
  background-size: 24px 24px;
  background-position: right 12px center;
  padding: 8px 48px 8px 12px;
  cursor: inherit;
  margin-right: 20px;
}
.contact-credentials__select select::-ms-expand {
  display: none;
}
.contact-credentials__edit {
  margin-right: 8px;
}
.contact-credentials__edit-quantity {
  display: flex;
  flex-direction: column;
}
.contact-credentials__edit-quantity .quantity-field {
  flex-direction: row;
  justify-content: center;
}
.contact-credentials__edit-quantity .button-group {
  display: flex;
  margin-top: 8px;
  flex-direction: row;
  justify-content: space-around;
}
.contact-credentials__edit-quantity .button-group .button-green span.icon svg {
  fill: #008000;
}
.contact-credentials__edit-quantity .button-group .button-red span.icon svg {
  fill: #e74c3c;
}
.contact-credentials__display {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.contact-credentials__display > .contact-credentials__display {
  flex-direction: row;
  width: 100%;
  padding: 0;
}
.contact-credentials__display > .contact-credentials__display + .contact-credentials__display {
  margin-top: 10px;
}
.contact-credentials__display-item {
  flex: 1 0 auto;
}
.contact-credentials__name {
  flex: 0 0 200px;
  margin-right: 20px;
}
.contact-credentials__name .credential-category__dot {
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 100%;
  margin-right: 5px;
}
.contact-credentials__name--type {
  display: flex;
  align-items: center;
}
.contact-credentials__name--type .icon {
  margin-left: 5px;
  width: 16px;
  height: 16px;
}
.credential-category__category,
.contact-credentials__date .date-item .date-item__period {
  color: #5a6879;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  opacity: 0.5;
  margin-bottom: 3px;
}
.contact-credentials__date {
  margin-right: 10px;
  font-size: 14px;
}
.contact-credentials__qty {
  flex: 0 0 auto;
}
.contact-credentials__qty .credential-quantity {
  display: flex;
}
.contact-credentials__qty .quantity-field input {
  font-size: 16px;
}
.status-indicator {
  display: flex;
  align-items: center;
}
.status-indicator .status-indicator__qty {
  display: inline-block;
  color: #fff;
  line-height: 24px;
  padding: 0 8px;
  font-weight: bold;
  border-radius: 2px 0 0 2px;
}
.status-indicator .status-indicator__qty + .status-indicator__icon {
  border-radius: 0 2px 2px 0;
}
.status-indicator .status-indicator__icon {
  display: inline-block;
  color: #fff;
  border-radius: 2px;
}
.status-indicator .status-indicator__icon .icon svg {
  transform: scale(0.8);
  fill: currentColor;
}
.expandable-row__column .status-indicator {
  float: right;
}
.expandable-row__column_contact {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
}
.add-cred {
  margin-left: 5px;
}
.add-cred .icon {
  width: 16px;
  height: 16px;
  color: #5a6879;
}
.add-cred .icon svg {
  fill: currentColor;
}
.remove-cred {
  margin-left: 5px;
}
.remove-cred .icon {
  width: 16px;
  height: 16px;
  color: #5a6879;
}
.remove-cred .icon svg {
  fill: currentColor;
}
.add-new-contact {
  display: flex;
  background: #fff;
  border: 1px solid #ccc;
  padding: 10px 20px;
  margin-top: 10px;
}
.add-new-contact__inputs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex: 1;
}
.add-new-contact__inputs .input-scaffold {
  width: 300px;
  padding-right: 20px;
}
.add-new-contact__cancel {
  display: flex;
  margin-left: 20px;
}
.department-guest .expandable-row__container .expandable-row > * + * {
  flex: 0 0;
  display: flex;
}
.department-guest .expandable-row__container .expandable-row > * + *:not(:last-child) {
  margin-left: 20px;
}
.catering-row {
  border-top: 1px solid #ccc;
  padding: 10px 20px;
}
.catering-row:first-child {
  border: none;
}
.catering-row__meal {
  font-weight: bold;
}
.catering-row__meal .catering-row__meal--select-all {
  color: #4183d7;
  cursor: pointer;
  float: right;
  font-size: 16px;
  width: 100px;
  padding: 1px;
}
.catering-row__meal .catering-row__meal--select-all:hover {
  color: #446cb3;
}
.catering-row__days {
  display: flex;
  flex-wrap: wrap;
}
.catering-row__days--single-day {
  display: flex;
  margin-right: 20px;
}
.catering-row__days--single-day .status-indicator {
  transform: scale(0.75);
}
.catering-row__days--single-day:last-child {
  margin: 0;
}
.catering-row__days--single-day .toggle-scaffold .toggle-label {
  font-size: 16px;
  padding-right: 2px;
}
.catering-row__days--single-day .toggle-scaffold .toggle-icon {
  margin-right: -10px;
}
.onsite-request__action-buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.onsite-request__action-button {
  display: flex;
  flex: 0 0 70px;
  background: transparent;
  border: 2px solid #4183d7;
  padding: 4px;
  border-radius: 4px;
  color: #4183d7;
  font-size: 16px;
  font-weight: 400;
  justify-content: center;
  align-items: center;
  height: 40px;
}
.onsite-request__action-button:first-of-type {
  margin-right: 0;
}
.onsite-request__action-button[disabled] {
  border-color: #808080;
  color: #808080;
}
.onsite-request__action-button:hover:not([disabled]) {
  background: #4183d7;
  color: #fff;
}
.onsite-request__action-button .spinner > div {
  background: #4183d7;
}
@media (max-width: 900px) {
  .staff-page {
    flex-direction: column-reverse;
  }
  .staff-page .staff-details {
    margin: 0;
    width: 100%;
  }
  .add-contact-form {
    left: -60px;
  }
}
.app-link {
  margin-top: 10px;
}
.app-link a {
  font-weight: 700;
}
.Select-menu-outer > div {
  opacity: 1 !important;
  z-index: 1000 !important;
  background: #fff;
}
body div.ReactTable *::-webkit-scrollbar {
  width: 2px !important;
  height: 2px !important;
}
body div.ReactTable *::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px #fff !important;
  border-radius: 1px !important;
}
body div.ReactTable *::-webkit-scrollbar-thumb {
  background: #808080 !important;
  border-radius: 1px !important;
}
.activity-log .ReactTable .rt-tbody .rt-tr-group {
  cursor: default;
}
.activity-log-action--requested {
  color: #dea74b;
}
.activity-log-action--approved {
  color: #2ecc71;
}
.public-application__header {
  background: #201c23;
  color: #fff;
  width: 100%;
  padding: 20px;
  font-size: 24px;
  font-weight: 400;
  text-align: center;
}
.public-application__body {
  width: 100%;
  background: #fff;
  margin: 0;
  border-radius: 0px;
  border: none;
}
@media (min-width: 900px) {
  .public-application__body {
    width: 960px;
    margin: 40px auto;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
}
.public-application__section {
  padding: 30px;
}
.public-application__section header {
  border-bottom: 1px solid #ccc;
  padding-bottom: 20px;
  margin-bottom: 30px;
}
.public-application__section article .input-scaffold {
  padding-bottom: 30px;
}
.public-application__image {
  max-width: 960px;
}
.public-application__image img {
  max-width: 100%;
  height: auto;
  margin: 0 auto;
}
.public-application__section--textcenter {
  text-align: center;
}
.public-application__section--title {
  font-size: 26px;
  font-weight: bold;
}
.public-application__section--desc {
  margin-bottom: 30px;
}
.public-application__section--closedate {
  margin-top: 20px;
}
.public-application__remove-personnel {
  display: block;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 20px;
}
.public-application__remove-personnel .click-target {
  padding: 5px;
  background-color: transparent;
  color: #e74c3c;
  border-radius: 2px;
  transition: all 0.25s ease;
}
.public-application__remove-personnel .click-target:hover {
  background-color: #e74c3c;
  color: #fff;
}
.public-application__section--placeholder {
  font-size: 36px;
  font-weight: 400;
  opacity: 0.2;
  margin: 60px 0 100px;
}
.public-application__section--placeholder.placeholder-success {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  opacity: 0.75;
  color: #2ecc71;
}
.public-application__section--placeholder.placeholder-success .icon {
  width: 40px;
  height: 40px;
  fill: #2ecc71;
  margin-right: 16px;
}
.intern-name-section {
  margin-bottom: 20px;
}
.application-custom-section .input-scaffold {
  margin-bottom: 32px;
}
.application-custom-section--title,
.undefined--title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}
.application-custom-section--desc,
.undefined--desc {
  margin: 0 0 20px;
}
.approval-interns__wrapper {
  display: flex;
  align-items: flex-start;
}
.contact-details__footer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}
.media-approval-page.intern-approval-page .intern-approval-page__applicant-info {
  padding: 20px;
}
.media-approval-page__wrapper {
  display: flex;
  align-items: flex-start;
}
.media-approval-page > * + * {
  margin-top: 10px;
}
.media-approval-page__form-answers {
  margin-top: 10px;
}
.media-approval-page__form-answers > * + * {
  margin-top: 10px;
}
.paper.applicant-info h3,
.paper.custom-field-info h3 {
  font-size: 20px;
  font-weight: 700;
  padding: 14px 20px;
  border-bottom: 1px solid #ccc;
}
.paper.applicant-info .fields-container {
  padding: 20px;
}
.paper.applicant-info .fields-container > * + * {
  margin-top: 16px;
}
.paper.applicant-info .fields-container--label {
  display: inline-block;
  font-weight: 700;
  width: 100%;
  max-width: 160px;
  margin-right: 10px;
}
.paper.custom-field-info > div {
  padding: 20px;
}
.paper.custom-field-info > div dt {
  font-weight: 700;
  width: 100%;
  max-width: 160px;
  margin-bottom: 8px;
}
.approve-media__desc {
  padding: 20px;
  line-height: 1.25;
}
.approve-button__footer {
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.approve-button__footer .deny .spinner > div {
  background-color: #4183d7;
}
.approvals > * + * {
  margin-top: 10px;
}
.approvals__section--zerocontent {
  margin: 20px;
}
.approvals__section--content {
  border-top: none;
}
.credential-approvals__department-row {
  overflow-x: scroll;
  background: #fff;
  border: 1px solid #ccc;
  margin-top: 10px;
}
.credential-approvals__department,
.credential-approvals__department-staff {
  display: flex;
  align-items: flex-start;
}
.credential-approvals__department-staff .credential-approvals__column1 {
  border-top: 1px solid #ccc;
}
.credential-approvals__department-staff .credential-approvals__period:before {
  content: "";
  display: block;
  width: 100%;
  border-top: 1px solid #ccc;
}
.credential-approvals__department-staff .credential-approvals__period:last-of-type {
  flex: 1;
}
.credential-approvals__department-staff .credential-approvals__credential-request .credential-name {
  color: #4183d7;
}
.credential-approvals__column1 {
  flex: 0 0 180px;
  padding: 10px;
}
.credential-approvals__column1 > * + * {
  margin-top: 5px;
}
.credential-approvals__column1 .text-link {
  font-size: 16px;
  display: block;
}
.credential-approvals__column1 .spinner > div {
  background: #4183d7;
}
.credential-approvals__department-name,
.credential-approvals__contact-name {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.credential-approvals__department-name .text-link,
.credential-approvals__contact-name .text-link {
  font-weight: bold;
}
.credential-approvals__department-name .icon,
.credential-approvals__contact-name .icon {
  width: 20px;
  height: 20px;
}
.credential-approvals__department-name .icon svg,
.credential-approvals__contact-name .icon svg {
  fill: #8c99a8;
}
.credential-approvals__periods {
  flex: 1;
  display: flex;
  align-items: flex-start;
}
.credential-approvals__period-card {
  border: 1px solid #ccc;
  margin: 10px 10px 10px 0;
  width: 300px;
}
.credential-approvals__period-card .credential-approvals__period-name {
  background: #8c99a8;
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  padding: 5px 10px;
}
.credential-approvals__requests--zerocontent {
  text-transform: uppercase;
  color: #8c99a8;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  opacity: 0.6;
  padding: 20px;
}
.credential-approvals__period-card-actions {
  display: flex;
  justify-content: space-around;
  border-top: 1px solid #ccc;
  padding: 10px;
  text-align: center;
}
.credential-approvals__period-card-actions .button {
  width: 100%;
  display: block;
  margin-bottom: 10px;
  padding: 4px 8px;
}
.credential-approvals__period-card-actions .text-link .spinner > div {
  background: #4183d7;
}
.credential-approvals__credential-type {
  padding: 5px 10px;
}
.credential-approvals__requests > * + * {
  border-top: 1px solid #ccc;
  padding-top: 3px;
}
.credential-approvals__category-group:not(:first-child) {
  margin-top: 10px;
}
.contact-credentials__name--category {
  display: flex;
}
.contact-credentials__name--category .credential-category__dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin-right: 5px;
  position: relative;
  top: 1px;
}
.contact-credentials__name--category .credential-category__category {
  font-size: 12px;
}
.credential-approvals__credential-request {
  border-top: 1px solid #ccc;
  display: flex;
  padding: 5px 10px;
}
.credential-approvals__credential-request .credential-tooltip {
  margin-right: 5px;
}
.credential-approvals__credential-request .credential-tooltip .icon {
  width: 16px;
  height: 16px;
  margin-top: 3px;
}
.credential-approvals__credential-request .credential-tooltip .icon svg {
  fill: #8c99a8;
}
.credential-approvals__credential-request .credential-status {
  margin-left: 5px;
}
.credential-approvals__credential-request .credential-status > * + * {
  margin-top: 5px;
}
.credential-approvals__credential-request .credential-name {
  flex: 1;
  line-height: 24px;
}
.credential-approvals__notes {
  flex: 0 0 initial;
  padding: 10px;
  margin: 10px;
  border: 1px solid #ccc;
}
.credential-approvals__notes .title {
  font-weight: bold;
}
.credential--expanded {
  background: rgba(140,153,168,0.2);
}
.credential--expanded .credential-name {
  font-weight: bold;
}
.credential--expanded__actions div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row wrap;
  margin: 0 10px;
}
.credential--expanded__actions div div {
  justify-content: center;
}
.credential--expanded__button {
  display: flex;
  flex: 0 0 135px;
  background: transparent;
  border: 2px solid #4183d7;
  padding: 4px;
  margin: 0 0 5px 0;
  border-radius: 4px;
  color: #4183d7;
  font-size: 16px;
  font-weight: 400;
  justify-content: center;
  align-items: center;
  height: 40px;
}
.credential--expanded__button:first-of-type {
  margin-right: 0;
}
.credential--expanded__button:hover {
  background: #4183d7;
  color: #fff;
}
.credential--expanded__button .spinner > div {
  background: #4183d7;
}
.credential-approvals__department-staff {
  display: flex;
  flex-wrap: wrap;
}
.credential-approvals__credentials {
  display: flex;
  width: 100%;
}
.credential-approvals__department-staff-overview {
  flex: 0 0 200px;
}
.credential-approvals__contact-actions > * {
  margin-top: 5px;
}
.credential-approval__change-pass-action select {
  display: inline-block;
  color: inherit;
  font-family: inherit;
  font-size: 16px;
  line-height: 20px;
  border: 2px solid #ccc;
  border-radius: 2px;
  appearance: none;
  padding-left: 20px;
  margin: 5px 10px;
  cursor: inherit;
  width: 100%;
  height: 40px;
  background: #fff;
  background-repeat: no-repeat;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAABYUlEQVRoge2YMU7EMBBF3yIuhFJyARo6aKHOCTYFHQU5AUegouMaEZehQ1pBgSMi5Dgz9hizYl4VxfbkP8lx7Oy6ruMYmKYpev/kl3OY4wKtcYHWuEBrXKA1LtAaF2hNjsCZeYqC2lqBHngFBu2DBAyhdq8ZpBHogcdw/YCtxBBqEp4hlpAKLMPPWEksw8+IJSQCO+Bipa1UIhZ+5lxSQCLwAVwDLyvtuRKp8E/AraSIdAq9A1ekJe6FtQh9U+FvgIOkkOYl3pK4A0ZBnTH0jaEKD/pldEtiT1piDH1iqMND3ocsV8I8PORvJbQSVcIDnOYMCswSz8BlpH2/cr2kKDyUCYBO4ifF4cFmN7o1nWKYhAe77bRGwiw82J4HJBKm4cH+QJOSMA8PdU5kMYkq4aF8FVpjuTq9USk81BOAb4kDlcJDXQH4kqjKv/wr8adwgda4QGtcoDUu0BoXaM3RC3wCkn5bVLDSEjUAAAAASUVORK5CYII=");
  background-size: 24px 24px;
  background-position: right 12px center;
}
.credential-approval__change-pass-action select::-ms-expand {
  display: none;
}
.credential-approval__change-pass-action--buttons {
  display: flex;
  justify-content: space-between;
  margin: 0 10px;
}
.credential-approval__change-qty-action .quantity-field {
  justify-content: center;
}
.credential-approval__change-qty-action--buttons {
  display: flex;
  justify-content: space-between;
  margin: 0 10px;
  flex-flow: row wrap;
}
.credential-approvals__catering {
  display: flex;
  width: 100%;
}
.credential-approvals__catering .credential-approvals__column1 {
  border: none;
}
.credential-approvals__catering .credential-approvals__column1 > * {
  margin-top: 5px;
}
.credential-approvals__meals {
  border: 1px solid #ccc;
  border-bottom: none;
  flex: 1;
}
.approval-filters {
  margin: 10px 0;
  padding: 10px 20px;
  background: rgba(140,153,168,0.3);
  border-radius: 2px;
}
.approval-filters__header {
  display: flex;
  align-items: center;
}
.approval-filters__header .approval-filters__header--title {
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  color: #5a6879;
}
.approval-filters__header .approval-filters__header--filter-on {
  background: #5a6879;
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 2px;
  padding: 4px 8px;
  margin-left: 10px;
}
.approval-filters__header .approval-filters__header--toggle {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
.approval-filters__header .approval-filters__header--toggle svg {
  fill: #5a6879;
}
.approval-filters__body form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 10px 0;
}
.approval-filters__body .input-scaffold {
  margin: 0 0 20px 0;
  flex: 0 0 50%;
}
.approval-filters__body .input-scaffold:nth-child(odd) {
  padding-right: 10px;
}
.approval-filters__body .input-scaffold:nth-child(even) {
  padding-left: 10px;
}
.approval-filters__body select {
  background-color: #fff;
}
.approval-filters__body .approval-filters__body--actions {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  height: 50%;
}
.approval-filters__body--collapsed {
  display: none;
}
.media-app > * + * {
  margin-top: 10px;
}
.media-app-std-res,
.media-app-custom-res dl {
  padding: 20px;
}
.media-info__wrapper > * {
  display: block;
}
.media-info__wrapper > * + * {
  margin-top: 20px;
}
.media-info dt {
  font-weight: 700;
  width: 100%;
  max-width: 160px;
  margin-bottom: 8px;
}
.media-app-custom-res > * + * {
  margin-top: 20px;
}
.sortable-meal {
  display: flex;
  align-items: center;
  padding: 10px 20px 10px 10px;
  margin-top: 10px;
  background: #fff;
  border: 1px solid #ccc;
  font-size: 18px;
  font-weight: bold;
}
.sortable-meal .drag-handle {
  margin-right: 10px;
}
.sortable-meal .meal-name {
  flex: 1;
  margin-right: 20px;
}
.sortable-meal .edit-meal .icon {
  width: 18px;
  height: 18px;
}
.documentation__wrapper p {
  padding-top: 8px;
  padding-bottom: 8px;
}
.documentation__wrapper h3 {
  margin-top: 8px;
  margin-bottom: 8px;
}
.documentation__wrapper .introduction--page li {
  margin-top: 8px;
  margin-bottom: 8px;
  background-image: url("../public/logo.png");
  background-repeat: no-repeat;
  padding-left: 24px;
  line-height: 24px;
}
.group-credential-assignment hr {
  background-color: #ddd;
  margin: 20px 0;
}
.group-credential-assigment__header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 100%;
  background-color: #201c23;
}
.group-credential-assigment__header h1 {
  font-size: 24px;
  font-weight: 400;
  color: #fff;
}
.group-credential-assigment__body {
  max-width: 1200px;
  margin: 30px auto 0;
}
.group-credential-assignment__body-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.group-credential-assignment__body-header h1 {
  font-size: 26px;
  margin-bottom: 20px;
}
.group-credential-assignment__body-header h2 {
  font-size: 20px;
  font-weight: 400;
}
.group-credential-assigment__section {
  margin: 40px auto 0;
  max-width: 960px;
}
.group-credential-assigment__section h3 {
  font-size: 20px;
}
.group-credential-assignment__header-row {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.group-credential-assignment__personnel-list > * + * {
  margin-top: 20px;
}
.available-credential {
  display: inline-flex;
  background-color: #f2f2f2;
  border-radius: 4px;
  box-shadow: 0 2px 0 #ddd;
  margin: 0 20px 20px 0;
}
.available-credential--not-available {
  opacity: 0.5;
}
.available-credential__info {
  padding: 10px;
}
.available-credential__category {
  font-size: 12px;
  color: #8c99a8;
  font-weight: 700;
  margin-bottom: 6px;
  text-transform: uppercase;
}
.available-credential__name {
  font-size: 16px;
  color: #5a6879;
}
.available-credential__quantity {
  padding: 10px;
  font-size: 36px;
  font-weight: 700;
  color: #5a6879;
  border-left: 1px solid #ddd;
}
.add-group-personnel {
  background: #f2f2f2;
  padding: 20px;
}
.add-group-personnel__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.add-group-personnel__credentials {
  margin-top: 40px;
}
.add-group-personnel__inline-inputs {
  display: flex;
  margin-bottom: 20px;
}
.add-group-personnel__inline-inputs > * + * {
  margin-left: 20px;
}
.add-group-personnel__name {
  flex-basis: 50%;
}
.add-group-personnel__email {
  flex-basis: 50%;
}
.add-group-personnel__contact-creds .contact-credentials__item {
  padding: 10px 0;
}
.add-group-personnel__meals .contact-catering .catering-row {
  padding: 10px 0;
}
.add-group-personnel__button-row {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.group-personnel {
  border: 1px solid #ddd;
}
.group-personnel__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
  border-bottom: 1px solid #ddd;
}
.group-personnel__edit-button .button {
  min-width: 0;
  padding: 0;
}
.group-personnel__edit-button .button .icon {
  fill: #201c23;
  margin: 0;
}
.group-personnel__lock .icon {
  fill: #201c23;
  margin: 0;
}
.group-personnel__name {
  font-size: 18px;
  font-weight: 700;
}
.group-personnel__email {
  margin-top: 5px;
}
.group-personnel__credentials {
  display: flex;
}
.group-personnel__credentials--sidebar {
  padding: 10px 20px;
  width: 230px;
  border-right: 1px solid #ddd;
  border-left: 8px solid #ddd;
}
.sidebar__title {
  display: flex;
  align-items: center;
}
.sidebar__title .icon {
  margin-right: 10px;
}
.group-personnel__credentials--list {
  width: 100%;
}
.group-personnel__credentials--list .contact-credentials__display {
  border-top: 0;
}
.group-personnel__credentials--list .contact-credentials {
  height: 100%;
}
.group-personnel__credentials--list .contact-credentials > * + * {
  border-top: 1px solid #ddd;
}
.group-personnel__credentials--list .date-item {
  margin-bottom: 0;
}
.contact-credentials h3 {
  margin: 40px 0 20px;
}
.contact-credentials__no-assigned-creds {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 20px;
  opacity: 0.5;
}
.onsite__logo {
  display: flex;
  align-items: center;
}
.onsite__logo > svg {
  height: 40px;
  fill: #446cb3;
}
.onsite__logo:after {
  content: "Onsite";
  color: #fff;
  display: inline-block;
  background: #446cb3;
  border-radius: 2px;
  padding: 2px 4px;
  margin-left: 5px;
  font-size: 12px;
  font-weight: bold;
}
.onsite__banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.onsite__banner > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.onsite__banner > div > span {
  font-size: larger;
}
.onsite__banner img {
  height: 75px;
  object-fit: contain;
}
.onsite-layout__body .input-scaffold {
  padding-top: 20px;
}
.onsite-layout__body .input-scaffold .input-label {
  font-size: medium;
}
.onsite-layout__body .input-scaffold input {
  font-size: medium;
  line-height: normal;
}
.onsite-layout__body .input-scaffold select {
  background-color: revert;
  font-size: medium;
  line-height: normal;
}
.onsite-select .react-select__control {
  height: 36px;
}
.onsite-select .react-select__placeholder {
  font-size: medium;
}
.onsite-select .react-select__value-container {
  font-size: medium;
}
.onsite-select .react-select__option {
  height: 30px;
  font-size: medium;
}
.onsite-select__department-type .react-select__menu {
  max-height: revert;
}
.onsite-select__department-type .react-select__menu-list {
  max-height: revert;
}
.onsite-select__contact-option {
  flex: 0 0 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.onsite-select__contact-option .icon {
  margin-left: 10px;
  width: 18px;
  height: 18px;
  fill: #ccc;
}
.onsite-layout__footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.onsite__status-button {
  float: right;
  margin-top: 20px;
  margin-bottom: 20px;
}
.onsite-checkbox__delete-contact .toggle-label {
  font-size: medium;
}
.onsite-expandable-row {
  cursor: default;
  height: 40px;
  min-height: 40px;
  font-size: inherit;
  padding: 5px;
}
.onsite-expandable-row .expandable-row__column:last-child {
  flex: 0.3;
}
.onsite__credential-header {
  margin-top: 20px;
  display: flex;
  align-items: center;
}
.onsite__credential-header .date-range {
  margin-left: 5px;
  font-size: smaller;
}
.onsite__credential-form {
  margin-left: 13px;
  padding: 0 20px 20px 15px;
  border-left: 2px dotted #d3d3d3;
}
.onsite__credential-form-buttons {
  margin-top: 10px;
  display: flex;
}
.onsite__credential-form-buttons .button,
.onsite__credential-form-buttons .button--secondary {
  padding: 0px;
  cursor: pointer;
}
::-webkit-input-placeholder {
  color: rgba(32,28,35,0.5);
}
:-moz-placeholder {
/* Firefox 18- */
  color: rgba(32,28,35,0.5);
}
::-moz-placeholder {
/* Firefox 19+ */
  color: rgba(32,28,35,0.5);
}
:-ms-input-placeholder {
/* IE 10+ */
  color: rgba(32,28,35,0.5);
}
::-ms-input-placeholder {
/* Edge */
  color: rgba(32,28,35,0.5);
}
:placeholder-shown {
/* Standard one last! */
  color: rgba(32,28,35,0.5);
}
.full-page-center {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-center {
  display: flex;
  align-items: center;
}
.flex-center--icon .icon {
  width: 20px;
  height: 20px;
  margin-right: 6px;
}
.width-auto {
  width: auto;
}
em {
  font-style: italic;
}
